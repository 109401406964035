import httpRequest from "~/utils/httpRequest";

const pathName = "categories/";

export const getAll = async () => {
  try {
    const res = await httpRequest.get(`${pathName}`, {});
    return res.data;
  } catch (err) {}
};

export const getAllHasNoChildren = async () => {
  try {
    const res = await httpRequest.get(`${pathName}`, {
      params: {
        children: "0",
      },
    });
    return res.data;
  } catch (err) {}
};

export const getAllHasChildren = async () => {
  try {
    const res = await httpRequest.get(`${pathName}`, {
      params: {
        children: "many",
      },
    });
    return res.data;
  } catch (err) {}
};

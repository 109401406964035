import clsx from "clsx";
import Image from "~/components/image/image";
import { paymentIcons, deliveryIcons, BCT } from "./index";

import styles from "./partTwo.module.scss";
import generalStyles from "~/styles/generalStyle.module.scss";




function PartTwo() {
  return (
    <div
      className={clsx(generalStyles.footerSectionContainer, styles.container)}
    >
      <div className={clsx(generalStyles.body, styles.content)}>
        <div className={styles.child}>
          <span className={clsx(generalStyles.title, styles.title)}>
            Các phương thưc thanh toán
          </span>
          <ul className={clsx(generalStyles.list, styles.imgsList)}>
            {paymentIcons.map((item) => (
              <Image className={styles.imgItem} src={item.icon} key={item.id} />
            ))}
          </ul>
        </div>
        <div className={styles.child}>
          <span className={clsx(generalStyles.title, styles.title)}>
            Vận chuyển
          </span>
          <ul
            className={clsx(
              generalStyles.list,
              styles.imgsList,
              styles.deliveryImgs
            )}
          >
            {deliveryIcons.map((item) => (
              <Image className={styles.imgItem} src={item.icon} key={item.id} />
            ))}
          </ul>
        </div>
        <div className={styles.child}>
          <span className={clsx(generalStyles.title, styles.title)}>
            {/* TOP Thương hiệu nổi bật */}
          </span>
          <ul
            className={clsx(
              generalStyles.list,
              styles.imgsList,
              styles.deliveryImgs
            )}
          >
            {BCT.map((item) => (
              <a href='http://online.gov.vn/Home/WebDetails/117804' style={{height:80, width:210}}>  <Image className={styles.imgItem} src={item.icon} key={item.id}  /></a>
            ))}
          </ul>
          <div ></div>
        </div>
        {/* <div className={styles.child}></div> */}
      </div>
    </div>
  );
}

export default PartTwo;

import clsx from "clsx";
import Image from "../image/image";
import IconButton from "../iconButton/iconButton";

import styles from "./iconMenuBar.module.scss";
import generalStyles from "~/styles/generalStyle.module.scss";

function IconMenuBar({
  menu,
  title,
  className,
  titleStyles,
  listStyles,
  itemStyles,
  iconStyles,
  setSubCateId,
  ...props
}) {
  return (
    <div className={clsx(generalStyles.section, styles.container, className)}>
      {title && (
        <span className={clsx(styles.title, titleStyles)}>{title}</span>
      )}
      <ul className={clsx(generalStyles.list, styles.list, listStyles)}>
        {menu.map((item, index) => {
          return (
            <IconButton
              className={clsx(styles.item, itemStyles)}
              href={item.link}
              textBtn={item.name}
              onClick={() => setSubCateId && setSubCateId(item._id)}
              key={index}
              {...props}
            >
              {/* <Image className={clsx(styles.icon, iconStyles)} src={item.img} /> */}
            </IconButton>
          );
        })}
      </ul>
    </div>
  );
}

export default IconMenuBar;

import { useState, useEffect } from "react";
import clsx from "clsx";
import Toast, { successfulToast, errorToast } from "~/components/toast/toast";
import Button from "~/components/button/button";
import PostMessage from "./postMessage/postMessage";

import styles from "./cooperativeSources.module.scss";

function CooperativeSources({ className }) {
  const [isShow, setIsShow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState();

  const handleShowModal = () => {
    isShow ? setIsShow(false) : setIsShow(true);
  };

  useEffect(() => {
    if (isSubmitted === "true") {
      successfulToast("Gửi thông tin thành công");
    } else if (isSubmitted === "false") {
      errorToast("Gửi thông tin không thành công");
    }
  }, [isSubmitted]);

  return (
    <div className={clsx(styles.container, className)}>
      <Toast />
      <Button
        className={styles.btn}
        textBtn="Đăng ký nguồn hàng"
        onClick={handleShowModal}
      />
      {isShow && (
        <PostMessage
          setIsShow={handleShowModal}
          setIsSubmitted={setIsSubmitted}
        />
      )}
    </div>
  );
}

export default CooperativeSources;

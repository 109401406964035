import React, { useState } from 'react';
import clsx from 'clsx';
import Button from '~/components/button/button';
import Image from '~/components/image/image';
import Input from '~/components/input/input';
import IconButton from '~/components/iconButton/iconButton';
import LogoText from '~/assets/images/BLACK.png';
import { socials, moreInfo } from './index';

import styles from './partOne.module.scss';
import generalStyles from '~/styles/generalStyle.module.scss';

function PartOne() {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({ label: 'Chưa check', value: 'not-check' }); // Example status

  const handleSubmit = async () => {
    try {
      const response = await fetch('https://server.asisell.com/api/messages/p', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          status: status
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Response:', data);
      window.alert('Cảm ơn bạn đã để lại email, chúng tôi sẽ sớm liên hệ.');
    } catch (error) {
      console.error('There was an error!', error);
      window.alert('Có lỗi xảy ra, vui lòng thử lại sau.');
    }
  };

  return (
    <div className={clsx(generalStyles.footerSectionContainer, styles.container)}>
      <div className={clsx(generalStyles.body, styles.content)}>
        <div className={clsx(styles.child, styles.partOne)}>
          <div className={styles.logoContainer}>
            <Image className={styles.logoImg} src={LogoText} alt="Slogans" />
          </div>
          <span className={styles.slogan}>
            Click chuột mua ngay - Săn ưu đãi mỗi ngày!
          </span>
        </div>
        <div className={clsx(styles.child, styles.partTwo)}>
          <ul className={styles.infoList}>
            {moreInfo.leftSide.map((info) => (
              <Button
                className={styles.infoItem}
                href={info.href}
                textBtn={info.title}
                isOpenOtherTab={true}
                key={info.id}
              />
            ))}
          </ul>
          <ul className={styles.infoList}>
            {moreInfo.rightSide.map((info) => (
              <li key={info.id}>
                <Button
                  className={styles.infoItem}
                  href={info.href}
                  textBtn={info.title}
                  isOpenOtherTab={true}
                />
                {info.link && (
                  <Button
                    className={styles.infoLink}
                    href={info.href}
                    textBtn={info.link}
                    isOpenOtherTab={true}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className={clsx(styles.child, styles.partThree)}>
          <ul className={styles.linkList}>
            {socials.map((social) => {
              let Icon = social.icon;
              return (
                <IconButton
                  className={styles.socialBtn}
                  href={social.link}
                  key={social.id}
                  isOpenOtherTab={true}
                >
                  <Icon className={styles.socialIcon} />
                </IconButton>
              );
            })}
          </ul>
          <span className={styles.title}>
            Đăng ký để nhận nhiều ưu đãi thú vị
          </span>
          <div className={styles.email}>
            <Input
              className={styles.input}
              placeholder="Email của bạn"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {/*(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]*/}
            <Button
              className={styles.submitBtn}
              textBtn="OK"
              onClick={handleSubmit}
            />
          </div>
          <br />
          <br />
          {/* <a href='http://online.gov.vn/Home/WebDetails/117804'><img alt='Bộ Công Thương' title='' style={{height: 80, width:210}} src='http://online.gov.vn/Content/EndUser/LogoCCDVSaleNoti/logoSaleNoti.png'/></a> */}
        </div>
      </div>
    </div>
  );
}

export default PartOne;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as messageService from "~/services/messageService";

export const getAll = createAsyncThunk(
  "message/getAll",
  async (params, thunkAPI) => {
    const currentMessages = await messageService.getAll();
    return currentMessages;
  }
);

export const addMessage = createAsyncThunk(
  "message/addMessage",
  async (data, thunkAPI) => {
    const currentMessages = await messageService.addMessage(data);
    return currentMessages;
  }
);

const messageSlice = createSlice({
  name: "message",
  initialState: {
    currentMessages: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = false;
      state.currentMessages = action.payload;
    });

    builder.addCase(addMessage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addMessage.fulfilled, (state, action) => {
      state.loading = false;
      state.currentMessages = action.payload;
    });
  },
});

const { reducer: messageReducer } = messageSlice;

export default messageReducer;

export const qnas = [
  {
    id: 1,
    title: "Về việc giao hàng của Asisell",
    subTitle: [
      {
        id: 1,
        title: "Bao lâu từ lúc đặt hàng thì tôi có thể nhận được hàng?",
        content: [
          {
            id: 1,
            text: "Nếu ở nội thành Hồ Chí Minh, khách hàng sẽ được nhận hàng trong vòng 24h kể từ khi có cuộc gọi hoặc tin nhắn xác nhận trừ trường hợp đơn chưa đủ tồn kho Asisell sẽ gửi đi từ kho vận hành khác tỉnh thì thời gian sẽ là 2-3 ngày.",
          },
          {
            id: 2,
            text: "Nếu ở tỉnh khác, khách hàng sẽ nhận được hàng trong 2-3 ngày.",
          },
          {
            id: 3,
            text: "Các trường hợp đơn hàng đặt trước (Pre-order) sẽ được thông báo về ngày giao hàng ở mỗi chương trình.",
          },
          {
            id: 4,
            text: "Tuy nhiên hiện tại do ảnh hưởng của dịch Covid 19, nên thời gian giao hàng ở một số nơi có thể lâu hơn dự kiến, mong các bạn thông cảm nhé!",
          },
        ],
      },
      {
        id: 2,
        title: "Làm thế nào để tôi có thể theo dõi đơn hàng?",
        content: [
          {
            id: 1,
            text: "Khách hàng có thể inbox vào fanpage Asisell với thông tin mã đơn hàng hoặc số điện thoại đặt hàng, chăm sóc khách hàng sẽ gửi đến khách hàng mã vận đơn trong thời gian sớm nhất.",
          },
        ],
      },
      {
        id: 3,
        title: "Khung giờ giao hàng của Asisell?",
        content: [
          {
            id: 1,
            text: "Asisell giao giờ hành chính, một số khu vực có thể hỗ trợ giao tối cho khách hàng. Asisell không cam kết có thể hỗ trợ các trường hợp giao tối 100% tuy nhiên Asisell chắc chắn sẽ làm mọi cách có thể hỗ trợ cho khách hàng.",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    title: "Trải nghiệm mua hàng trực tiếp",
    subTitle: [
      {
        id: 1,
        title: "Asisell có cửa hàng không?",
        content: [
          {
            id: 1,
            text: "Hiện tại Asisell không có cửa hàng. Asisell có 2 Hub vận hành và có hỗ trợ khách hàng mua trực tiếp tại Hub để có những trải nghiệm thực tế nhất.",
          },
          {
            id: 2,
            text: "+ Asisell HCM: Lầu 1, Số 163 Trần Trọng Cung, Phường Tân Thuận Đông, Quận 7, Tp. Hồ Chí Minh.",
          },
          {
            id: 3,
            text: "Giờ làm việc: 8h30 - 17h30 các ngày trừ Chủ nhật và ngày lễ.",
          },
        ],
      },
      {
        id: 2,
        title: "Chính sách giá được áp dụng như thế nào khi tôi mua trực tiếp?",
        content: [
          {
            id: 1,
            text: "Mọi đơn hàng của khách hàng đều được tính giá như giá trên website. Khách hàng yên tâm, các mã giảm giá chương trình quà tặng,... vẫn được hỗ trợ như khi đặt online.",
          },
        ],
      },
      {
        id: 3,
        title: "Các hình thức thanh toán khi tôi mua hàng trực tiếp là gì?",
        content: [
          {
            id: 1,
            text: "Cũng giống như mua hàng online khách hàng mua hàng tại kho có 2 hình thức để thanh toán đơn hàng: tiền mặt hoặc chuyển khoản ngân hàng. Asisell chưa áp dụng hình thức quẹt thẻ.",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: "Đổi/trả hàng với Asisell",
    subTitle: [
      {
        id: 1,
        title: "Thế nào là đơn hàng 2 chiều?",
        content: [
          {
            id: 1,
            text: "Đơn hàng 2 chiều điển hình ở đơn đổi hàng ở Asisell, khách hàng sẽ nhận hàng mới và đồng thời gửi hàng đổi cho bưu tá giao hàng.",
          },
        ],
      },
      {
        id: 2,
        title: "Tôi đổi hàng tại kho có tính ship không?",
        content: [
          {
            id: 1,
            text: "Không. Asisell luôn hỗ trợ khách hàng với trải nghiệm tốt nhất, khách hàng có thể báo với Asisell về tình trạng đổi của đơn hàng Asisell sẽ đóng sẵn hàng khách hàng chỉ cần ghé kho và đổi hàng ngay.",
          },
        ],
      },
      {
        id: 3,
        title: "Tôi trả hàng, Asisell hoàn tiền cho tôi như thế nào?",
        content: [
          {
            id: 1,
            text: "Tại Asisell, ngay khi có yêu cầu trả hàng hoàn tiền, chúng tôi sẽ hoàn tiền cho khách hàng trong vòng 24h, hàng sẽ được nhận lại sau đó. Cụ thể:",
          },
          {
            id: 2,
            text: "Bước 1: Khách hàng thông báo với Asisell về đơn hàng cần trả tại đây. Sau khi nhận thông tin Asisell sẽ liên hệ xác nhận và thực hiện thủ tục hoàn tiền qua STK Ngân hàng – tiền sẽ được hoàn trong tối đa 24h (không tính thứ 7 và chủ nhật). Bạn lưu ý giúp Asisell, các yêu cầu hoàn tiền được tổng hợp muộn nhất là 16h hàng ngày. Các yêu cầu hoàn tiền sau 16h được chuyển vào ngày hôm sau nên chậm nhất là ngày hôm sau khách nhận được tiền.",
          },
          {
            id: 3,
            text: "Bước 2: Asisell nhận lại hàng trả bằng 2 cách",
          },
          {
            id: 4,
            text: "Cách 1: Asisell đến tận nơi lấy hàng, không thu bất kỳ chi phí gì.",
          },
          {
            id: 5,
            text: "+ Hồ Chí Minh: Lầu 1, Số 163 Trần Trọng Cung, Phường Tân Thuận Đông, Quận 7, Tp. Hồ Chí Minh",
          },
          {
            id: 6,
            text: "Thời gian nhận hàng từ 8h30 - 17h30 (Thứ 2 - Thứ 7)",
          },
          {
            id: 7,
            text: "Sau khi gửi khách hàng hỗ trợ chụp lại hóa đơn của hãng vận chuyển và gửi qua inbox để Asisell có thể tiện theo dõi đơn hàng.",
          },
        ],
      },
    ],
  },
  {
    id: 4,
    title: "Các vấn đề liên quan đến đơn hàng",
    subTitle: [
      {
        id: 1,
        title:
          "Tôi muốn thay đổi thông tin với đơn hàng đã đặt (SĐT nhận hàng, địa chỉ, hình thức thanh toán,...)",
        content: [
          {
            id: 1,
            text: "Khách hàng inbox vào fanpage Asisell hoặc gọi hotline 079 450 7036 để chăm sóc khách hàng hỗ trợ thay đổi thông tin đơn hàng của khách hàng.",
          },
          {
            id: 2,
            text: "Riêng về việc thay đổi hình thức thanh toán từ COD sang thanh toán trước, Asisell sẽ gửi đến khách thông tin STK:",
          },
          {
            id: 3,
            text: "Công ty TNHH Asisell",
          },
          {
            id: 4,
            text: "214324325453",
          },
          {
            id: 5,
            text: "Vietcombank CN Tây Hà Nội",
          },
          {
            id: 6,
            text: "Ngay khi nhận được thanh toán, Asisell sẽ cập nhật lại thông tin thanh toán cho khách hàng.",
          },
        ],
      },
      {
        id: 2,
        title: "Tôi muốn huỷ đơn hàng đã thanh toán trước?",
        content: [
          {
            id: 1,
            text: "Hiện tại Asisell chưa thể hoàn tiền tự động cho khách hàng. Khách hàng điền thông tin ở đây hoặc inbox Fanpage hoặc gọi hotline 079 450 7036, Asisell sẽ hoàn lại tiền trong vòng 24h sau khi nhận được thông tin.",
          },
        ],
      },
    ],
  },
];

import httpRequest from "~/utils/httpRequest";

const pathName = "users/";

export const getAll = async () => {
  try {
    const res = await httpRequest.get(`${pathName}`, {});
    return res.data;
  } catch (err) {}
};

export const getUserById = async (params) => {
  try {
    const res = await httpRequest.get(`${pathName}g/`, {
      params: {
        id: params,
      },
    });
    return res.data;
  } catch (err) {}
};

export const getUserByGoogleId = async (params) => {
  try {
    const res = await httpRequest.get(`${pathName}g/`, {
      params: {
        google_id: params,
      },
    });
    return res.data;
  } catch (err) {}
};

export const addUser = async (data) => {
  try {
    const res = await httpRequest.post(`${pathName}p/`, data, {});
    return res.data;
  } catch (err) {}
};

export const editUserById = async (data) => {
  try {
    const res = await httpRequest.put(`${pathName}u/`, data.body, {
      params: {
        id: data.id,
      },
    });
    return res.data;
  } catch (err) {}
};

export const addDeliveryAddress = async (data) => {
  try {
    const res = await httpRequest.post(`${pathName}p/`, data.body, {
      params: {
        id: data.userId,
        field: "delivery_address",
      },
    });
    return res.data;
  } catch (err) {}
};

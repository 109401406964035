import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as cooperativeSourceService from "~/services/cooperativeSourceService";

export const getAll = createAsyncThunk(
  "cooperativeSource/getAll",
  async (params, thunkAPI) => {
    const currentCooperativeSources = await cooperativeSourceService.getAll();
    return currentCooperativeSources;
  }
);

export const addCooperativeSource = createAsyncThunk(
  "cooperativeSource/addCooperativeSource",
  async (data, thunkAPI) => {
    const currentCooperativeSources =
      await cooperativeSourceService.addCooperativeSource(data);
    return currentCooperativeSources;
  }
);

const cooperativeSourceSlice = createSlice({
  name: "cooperativeSource",
  initialState: {
    currentCooperativeSources: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = false;
      state.currentCooperativeSources = action.payload;
    });

    builder.addCase(addCooperativeSource.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCooperativeSource.fulfilled, (state, action) => {
      state.loading = false;
      state.currentCooperativeSources = action.payload;
    });
  },
});

const { reducer: cooperativeSourceReducer } = cooperativeSourceSlice;

export default cooperativeSourceReducer;

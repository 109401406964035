import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as paymentMethodService from "~/services/paymentMethodService";

export const getAll = createAsyncThunk(
  "paymentMethod/getAll",
  async (params, thunkAPI) => {
    const currentPaymentMethods = await paymentMethodService.getAll();
    return currentPaymentMethods;
  }
);

const paymentMethodSlice = createSlice({
  name: "paymentMethod",
  initialState: {
    currentPaymentMethods: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = false;
      state.currentPaymentMethods = action.payload;
    });
  },
});

const { reducer: paymentMethodReducer } = paymentMethodSlice;

export default paymentMethodReducer;

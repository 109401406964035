import { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import SelectionInput from "~/components/selectionInput/selectionInput";
import TextAreaSection from "../section/textAreaSection/textAreaSection";
import { fetchApi } from "~/utils/common";
import * as locationSlice from "~/store/slice/locationSlice";

import styles from "./addressChoice.module.scss";

function AddressChoice({
  setAddress,
  setIsMenuOpen,
  title,
  className,
  textAreaStyles,
  addressContainerStyles,
  isDisabled,
}) {
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [ward, setWard] = useState("");
  const [street, setStreet] = useState("");

  const districtRef = useRef();
  const wardRef = useRef();
  const streetRef = useRef();

  const dispatch = useDispatch();

  const selectInputStyles = {
    control: (styles, state) => ({
      ...styles,
      fontSize: "1.5rem",
      outline: "none",
      boxShadow: "0 !important",
    }),
  };

  const handleCity = (e) => {
    if (city !== undefined) {
      districtRef.current.clearValue();
      wardRef.current.clearValue();
      setWards([]);
      setDistrict("");
      setWard("");
    }
  };

  const handleDistrict = (e) => {
    if (district !== undefined) {
      wardRef.current.clearValue();
      setWards([]);
      setWard("");
    }
  };

  const selectCity = (value) => {
    handleCity();
    setCity(value);
  };

  const selectDistrict = (value) => {
    handleDistrict();
    setDistrict(value);
  };

  const selectWard = (value) => {
    setWard(value);
  };

  useEffect(() => {
    const getObject = (value, setList) => {
      const list = value.map((item, index) => ({
        ...item,
        label: item.province_name,
        index,
      }));
      setList(list);
    };

    const getAllCities = () => fetchApi(locationSlice.getAllCities(), dispatch);

    getAllCities().then((result) => getObject(result.results, setCities));
  }, []);

  useEffect(() => {
    const cityId = city.province_id;

    const getObject = (value, setList) => {
      const list = value.map((item, index) => ({
        ...item,
        label: item.district_name,
        index,
      }));
      setList(list);
    };

    const getDistricts = () =>
      fetchApi(locationSlice.getDistrictsByCity(cityId), dispatch);

    cityId &&
      getDistricts().then((result) => getObject(result.results, setDistricts));
  }, [city]);

  useEffect(() => {
    const districtId = district.district_id;

    const getObject = (value, setList) => {
      const list = value.map((item, index) => ({
        ...item,
        label: item.ward_name,
        index,
      }));
      setList(list);
    };

    const getWards = () =>
      fetchApi(locationSlice.getWardsByDistrict(districtId), dispatch);

    districtId &&
      getWards().then((result) => getObject(result.results, setWards));
  }, [district]);

  useEffect(() => {
    const selectedWard = ward.ward_name || "";
    const selectedDistrict = district.district_name || "";
    const selectedCity = city.province_name || "";

    setAddress({
      ward: selectedWard,
      district: selectedDistrict,
      city: selectedCity,
      street,
    });
  }, [ward, district, city, street]);

  return (
    <div className={clsx(styles.container, className)}>
      <div className={addressContainerStyles}>
        {title && <span className={styles.title}>{title}</span>}
        <div className={styles.addressContainer}>
          <SelectionInput
            className={styles.selectionInput}
            placeholder="Chọn Tỉnh/Thành phố"
            selectInputStyles={selectInputStyles}
            options={cities}
            onChange={selectCity}
            onMenuOpen={() => setIsMenuOpen(true)}
            onMenuClose={() => setIsMenuOpen(false)}
            isDisabled={isDisabled}
          />
          <SelectionInput
            ref={districtRef}
            className={styles.selectionInput}
            placeholder="Chọn Quận/Huyện"
            selectInputStyles={selectInputStyles}
            options={districts}
            onChange={selectDistrict}
            onMenuOpen={() => setIsMenuOpen(true)}
            onMenuClose={() => setIsMenuOpen(false)}
            isDisabled={isDisabled}
          />
          <SelectionInput
            ref={wardRef}
            className={styles.selectionInput}
            placeholder="Chọn Phường/Xã"
            selectInputStyles={selectInputStyles}
            options={wards}
            onChange={selectWard}
            onMenuOpen={() => setIsMenuOpen(true)}
            onMenuClose={() => setIsMenuOpen(false)}
            isDisabled={isDisabled}
          />
        </div>
      </div>
      <TextAreaSection
        rows={2}
        className={textAreaStyles}
        ref={streetRef}
        placeholder="Nhập số nhà, tên đường, ..."
        setState={setStreet}
        isDisabled={isDisabled}
      />
    </div>
  );
}

export default AddressChoice;

import clsx from "clsx";
import styles from "./checkBox.module.scss";

function CheckBox({ className, title, isChecked, onClick, onChange }) {
  return (
    <label className={clsx(styles.container, className)} onClick={onClick}>
      <span className={styles.title}>{title}</span>
      <input
        type="checkbox"
        className={styles.input}
        checked={isChecked}
        onChange={onChange}
      />
      <span className={styles.checkMark}></span>
    </label>
  );
}

export default CheckBox;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as bannerService from "~/services/bannerService";

export const getAll = createAsyncThunk(
  "banner/getAll",
  async (params, thunkAPI) => {
    const currentBanners = await bannerService.getAll();
    return currentBanners;
  }
);

const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    currentBanners: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = false;
      state.currentBanners = action.payload;
    });
  },
});

const { reducer: bannerReducer } = bannerSlice;

export default bannerReducer;

import { useState, useEffect } from "react";
import clsx from "clsx";
import Tippy from "@tippyjs/react/headless";
import Image from "../image/image";
import IconButton from "../iconButton/iconButton";
import MobileMenu from "../mobile/mobileMenu/mobileMenu";
import Login from "../sign/login/login";
import Logout from "../sign/logout/logout";
import CartComponent from "../cartComponent/cartComponent";
import SearchComponent from "../search/searchComponent/searchComponent";
import SaleRegistration from "../message/saleRegistration/saleRegistration";
import CooperativeSources from "../message/cooperativeSources/cooperativeSources";
import FlagVNIcon from "~/assets/icons/flagVNIcon";
import HomeIcon from "~/assets/icons/homeIcon";
import LogoText from "~/assets/images/BLACK.png";

import styles from "./header.module.scss";
import generalStyles from "~/styles/generalStyle.module.scss";
const PostMessage = ({ className }) => (
  <div className={clsx(styles.postMessage, className)}>
    <SaleRegistration className={styles.saleRegistration} />
    <CooperativeSources className={styles.cooperativeSources} />
  </div>
);
function Header() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const renderProfile = (attrs) => (
    <div
      tabIndex="-1"
      {...attrs}
      className={clsx(generalStyles.section, styles.menuContainer)}
    >
      <IconButton
        className={styles.profileBtn}
        href="/profile"
        textBtn="Trang cá nhân"
      >
        <i className="fa-solid fa-user"></i>
      </IconButton>
      <Logout className={styles.profileBtn} />
    </div>
  );

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("isLoggedIn") === "true");
  }, []);

  return (
    <div className={styles.container}>
      <div className={clsx(generalStyles.body, styles.contentContainer)}>
        <div className={clsx(generalStyles.content, styles.content)}>
          <div className={styles.topSection}>
            <MobileMenu className={styles.mobileMenu} />
            <IconButton className={styles.homeBtn} href="/">
              <HomeIcon className={styles.homeIcon} />
            </IconButton>
            <span>Nền Tảng Bán Hàng Nông Sản Việt</span>
            <div className={styles.loginAndFlag}>
              <div className={styles.loginContainer}>
                <Tippy
                  interactive
                  placement="bottom"
                  trigger={isLoggedIn ? "click" : ""}
                  render={renderProfile}
                >
                  <div>
                    <Login className={styles.loginBtn} />
                  </div>
                </Tippy>
              </div>
              <IconButton
                className={clsx(styles.btn, styles.flagBtn)}
                textBtn="VIE"
              >
                <FlagVNIcon className={clsx(styles.icon, styles.flagIcon)} />
              </IconButton>
            </div>
            <CartComponent className={styles.cart} />
          </div>
          <div className={styles.bottomSection}>
            <div className={styles.homeContainer}>
              <IconButton className={styles.home} to="/">
                <Image className={styles.homeImg} src={LogoText} />
              </IconButton>
              {/* <IconButton className={styles.club}>
                <Image className={styles.clubImg} src={AsiclubLogo} />
              </IconButton> */}
            </div>
            <SearchComponent className={styles.search} />
            <CartComponent className={styles.cart} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;

import clsx from "clsx";
import PartOne from "./components/partOne/partOne";
import PartTwo from "./components/partTwo/partTwo";
import PartThree from "./components/partThree/partThree";
import PartFour from "./components/partFour/partFour";
import PartFive from "./components/partFive/partFive";

import styles from "./footer.module.scss";
import generalStyles from "~/styles/generalStyle.module.scss";


function Footer() {

  return (
    
    
    <div className={clsx(styles.container, generalStyles.footerText)}>
      <PartOne />
      <PartTwo />
      <PartThree />
      <PartFour />
      <PartFive />
    </div>
  );
}

export default Footer;

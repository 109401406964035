import { useState, useEffect } from "react";
import clsx from "clsx";
import ImageGallery from "react-image-gallery";

import styles from "./imageSlider.module.scss";
import "./slider.scss";
import "react-image-gallery/styles/scss/image-gallery.scss";

function ImageSlider({ className, imageList }) {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const images = imageList.map((image) => ({
      original: image,
    }));

    setImages(images);
  }, [imageList]);

  return (
    <div className={clsx(styles.container, className)}>
      <ImageGallery
        items={images}
        lazyLoad
        autoPlay
        showPlayButton={false}
        showFullscreenButton={false}
      />
    </div>
  );
}

export default ImageSlider;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as searchService from "~/services/searchService";

export const getAll = createAsyncThunk(
  "search/getAll",
  async (params, thunkAPI) => {
    const currentSearches = await searchService.getAll();
    return currentSearches;
  }
);

const searchSlice = createSlice({
  name: "search",
  initialState: {
    currentSearches: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.currentSearches = action.payload;
    });
  },
});

const { reducer: searchReducer } = searchSlice;

export default searchReducer;

import { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import IconButton from "~/components/iconButton/iconButton";
import LeftSide from "~/components/leftSide/leftSide";
import Login from "~/components/sign/login/login";
import Logout from "~/components/sign/logout/logout";
import { menu } from "./index";
import FlagVNIcon from "~/assets/icons/flagVNIcon";

import styles from "./mobileMenu.module.scss";

function MobileMenu({ className, btnStyles }) {
  const [isShow, setIsShow] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isProducts, setIsProducts] = useState(false);

  const handleShow = () => {
    isShow ? setIsShow(false) : setIsShow(true);
  };

  useEffect(() => {
    setIsProducts(window.location.pathname.startsWith("/products"));
    setIsLoggedIn(localStorage.getItem("isLoggedIn") === "true");
  }, []);

  return (
    <div className={clsx(styles.container, className)}>
      <IconButton
        className={clsx(styles.menuBtn, btnStyles)}
        onClick={handleShow}
      >
        <i className="fa-solid fa-bars"></i>
      </IconButton>
      <div
        className={clsx(
          styles.overlay,
          isShow ? styles.fadeIn : styles.fadeOut
        )}
        onClick={handleShow}
      >
        <div
          className={clsx(
            styles.contentContainer,
            isShow ? styles.moveIn : styles.moveOut,
            className
          )}
        >
          <div className={clsx(styles.section, styles.login)}>
            <Login
              className={styles.loginBtn}
              profileBtnStyles={styles.profile}
            />
            {isLoggedIn && (
              <div className={styles.menu}>
                {menu.map((item) => {
                  return (
                    <IconButton
                      className={styles.btn}
                      to={item.href}
                      textBtn={item.title}
                      key={item.id}
                    >
                      {item.icon}
                    </IconButton>
                  );
                })}
                <Logout className={styles.btn} />
              </div>
            )}
          </div>
          <LeftSide
            className={styles.leftSide}
            element={styles.container}
            isFilter={isProducts}
            isForYou={isProducts ? false : true}
            // isCategory={isProducts ? false : true}
            isSearchResult={true}

            
          />
          <IconButton
            className={clsx(styles.btn, styles.flagBtn)}
            textBtn="VIE"
          >
            <FlagVNIcon className={clsx(styles.icon, styles.flagIcon)} />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import IconMenuBar from "~/components/iconMenuBar/iconMenuBar";
import { fetchApi } from "~/utils/common";
import * as categorySlice from "~/store/slice/categorySlice";

function ForYou({ className, listStyles, itemStyles }) {
  const [forYou, setForYou] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const cate = () => fetchApi(categorySlice.getAll(), dispatch);

    cate().then((result) => {
      const results = result.map((item) => ({
        ...item,
        link: `/products/${item._id}`,
      }));

      localStorage.setItem("categoryId", result[0] ? result[0]._id : "");

      setForYou(results);
    });
  }, []);

  return (
    <IconMenuBar
      menu={forYou}
      title="Danh mục sản phẩm"
      className={className}
      listStyles={listStyles}
      itemStyles={itemStyles}
    />
  );
}

export default ForYou;

export const filter = [
  {
    id: 1,
    title: "Giá thấp đến cao",
    filter: "ascending",
  },
  {
    id: 2,
    title: "Giá cao đến thấp",
    filter: "descending",
  },
  {
    id: 3,
    title: "Tên từ a -> z",
    filter: "alphabet",
  },
];

import clsx from "clsx";
import { infos } from "./index";

import styles from "./partThree.module.scss";
import generalStyles from "~/styles/generalStyle.module.scss";

function PartThree() {
  return (
    <div
      className={clsx(generalStyles.footerSectionContainer, styles.container)}
    >
      <ul
        className={clsx(generalStyles.body, generalStyles.list, styles.content)}
      >
        {infos.map((info) => (
          <div className={styles.info} key={info.id}>
            {info.title && <span className={styles.title}>{info.title} </span>}
            {info.text && <span className={styles.text}>{info.text}</span>}
          </div>
        ))}
      </ul>
    </div>
  );
}

export default PartThree;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as productService from "~/services/productService";

export const getAllProducts = createAsyncThunk(
  "product/getAllProducts",
  async (params, thunkAPI) => {
    const currentProducts = await productService.getAllProducts();
    return currentProducts;
  }
);

export const getAllBySearch = createAsyncThunk(
  "product/getAllBySearch",
  async (params, thunkAPI) => {
    const currentProducts = await productService.getAllBySearch(params);
    return currentProducts;
  }
);

export const getProductById = createAsyncThunk(
  "product/getProductById",
  async (params, thunkAPI) => {
    const currentProducts = await productService.getProductById(params);
    return currentProducts;
  }
);

export const getProductsBySubCategory = createAsyncThunk(
  "product/getProductsBySubCategory",
  async (params, thunkAPI) => {
    const currentProducts = await productService.getProductsBySubCategory(
      params
    );
    return currentProducts;
  }
);


export const getProductsByCategory = createAsyncThunk(
  "product/getProductsByCategory",
  async (params, thunkAPI) => {
    const currentProducts = await productService.getProductsByCategory(
      params
    );
    return currentProducts;
  }
);

export const getAllWithOneGift = createAsyncThunk(
  "product/getAllWithOneGift",
  async (params, thunkAPI) => {
    const currentProducts = await productService.getAllWithOneGift();
    return currentProducts;
  }
);

export const getAllFromTwoGifts = createAsyncThunk(
  "product/getAllFromTwoGifts",
  async (params, thunkAPI) => {
    const currentProducts = await productService.getAllFromTwoGifts();
    return currentProducts;
  }
);

export const filterAscending = createAsyncThunk(
  "product/filterAscending",
  async (params, thunkAPI) => {
    const currentProducts = await productService.filterAscending(params);
    return currentProducts;
  }
);

export const filterDescending = createAsyncThunk(
  "product/filterDescending",
  async (params, thunkAPI) => {
    const currentProducts = await productService.filterDescending(params);
    return currentProducts;
  }
);

export const filterAlphabet = createAsyncThunk(
  "product/filterAlphabet",
  async (params, thunkAPI) => {
    const currentProducts = await productService.filterAlphabet(params);
    return currentProducts;
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: {
    currentProducts: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllProducts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllProducts.fulfilled, (state, action) => {
      state.loading = false;
      state.currentProducts = action.payload;
    });

    builder.addCase(getAllBySearch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllBySearch.fulfilled, (state, action) => {
      state.loading = false;
      state.currentProducts = action.payload;
    });

    builder.addCase(getProductById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProductById.fulfilled, (state, action) => {
      state.loading = false;
      state.currentProducts = action.payload;
    });

    builder.addCase(getProductsBySubCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProductsBySubCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.currentProducts = action.payload;
    });

    builder.addCase(getProductsByCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProductsByCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.currentProducts = action.payload;
    });

    builder.addCase(getAllWithOneGift.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllWithOneGift.fulfilled, (state, action) => {
      state.loading = false;
      state.currentProducts = action.payload;
    });

    builder.addCase(getAllFromTwoGifts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllFromTwoGifts.fulfilled, (state, action) => {
      state.loading = false;
      state.currentProducts = action.payload;
    });

    builder.addCase(filterAscending.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(filterAscending.fulfilled, (state, action) => {
      state.loading = false;
      state.currentProducts = action.payload;
    });

    builder.addCase(filterDescending.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(filterDescending.fulfilled, (state, action) => {
      state.loading = false;
      state.currentProducts = action.payload;
    });

    builder.addCase(filterAlphabet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(filterAlphabet.fulfilled, (state, action) => {
      state.loading = false;
      state.currentProducts = action.payload;
    });
  },
});

const { reducer: productReducer } = productSlice;

export default productReducer;

import httpRequest from "~/utils/httpRequest";

const pathName = "orders/";

export const getAll = async () => {
  try {
    const res = await httpRequest.get(`${pathName}`, {});
    return res.data;
  } catch (err) {}
};

export const getOrderByUser = async (params) => {
  try {
    const res = await httpRequest.get(`${pathName}g/`, {
      params: {
        user: params,
      },
    });
    return res.data;
  } catch (err) {}
};

export const getAllByPending = async () => {
  try {
    const res = await httpRequest.get(`${pathName}g/`, {
      params: {
        status: "pending",
      },
    });
    return res.data;
  } catch (err) {}
};

export const getAllBySuccess = async () => {
  try {
    const res = await httpRequest.get(`${pathName}g/`, {
      params: {
        status: "successful",
      },
    });
    return res.data;
  } catch (err) {}
};

export const getAllByCancel = async () => {
  try {
    const res = await httpRequest.get(`${pathName}g/`, {
      params: {
        status: "canceled",
      },
    });
    return res.data;
  } catch (err) {}
};

export const getOrderById = async (params) => {
  try {
    const res = await httpRequest.get(`${pathName}g/`, {
      params: {
        id: params,
      },
    });
    return res.data;
  } catch (err) {}
};

export const addOrder = async (data) => {
  try {
    const res = await httpRequest.post(`${pathName}p/`, data.body, {
      params: {
        cart_id: data.cart_id,
      },
    });
    return res.data;
  } catch (err) {}
};

export const editOrder = async (data) => {
  try {
    const res = await httpRequest.put(`${pathName}u/`, data.body, {
      params: {
        id: data.id,
      },
    });
    return res.data;
  } catch (err) {}
};

export const deleteOrderById = async (params) => {
  try {
    const res = await httpRequest.delete(`${pathName}d/`, {
      params: {
        id: params,
      },
    });
    return res.data;
  } catch (err) {}
};

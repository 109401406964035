import httpRequest from "~/utils/httpRequest";

const pathName = "searchs/";

export const getAll = async () => {
  try {
    const res = await httpRequest.get(`${pathName}`, {});
    return res.data;
  } catch (err) {}
};

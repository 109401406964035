import FacebookIcon from "~/assets/icons/facebookIcon";
import TiktokIcon from "~/assets/icons/tiktokIcon";
import InstagramIcon from "~/assets/icons/instagramIcon";
import YoutubeIcon from "~/assets/icons/youtubeIcon";
import { socialLinks } from "~/constants/social";

const socials = [
  {
    id: 1,
    icon: FacebookIcon,
    link: socialLinks.facebook,
    alt: "Facebook",
  },
  {
    id: 2,
    icon: TiktokIcon,
    link: socialLinks.tiktok,
    alt: "Tiktok",
  },
  {
    id: 3,
    icon: InstagramIcon,
    link: socialLinks.instagram,
    alt: "Instagram",
  },
  {
    id: 4,
    icon: YoutubeIcon,
    link: socialLinks.youtube,
    alt: "Youtube",
  },
 
];

const moreInfo = {
  leftSide: [
    {
      id: 1,
      title: "Giới thiệu về Asisell",
      href: "/gt-policy",
    },
    {
      id: 2,
      title: "Chính sách bảo mật",
      href: "/security-policy",
    },
    {
      id: 3,
      title: "Chính sách thanh toán",
      href: "/payment-policy",
    },
    {
      id: 4,
      title: "Chính sách khuyến mãi",
      href: "/promotion-policy",
    },
    {
      id: 5,
      title: "Chính sách đổi trả - hoàn - hủy",
      href: "/returns-policy",
    },
    {
      id: 6,
      title: "Chính sách vận chuyển",
      href: "/delivery-policy",
    },
    {
      id: 7,
      title: "Hướng dẫn thanh toán VNPAY",
      href: "/vnpay-policy",
    },
  ],
  rightSide: [
    {
      id: 1,
      title: "Blog",
      href: "#",
    },
    {
      id: 2,
      title: "Đối tác",
      href: "dt-policy",
    },
    {
      id: 3,
      title: "Quy chế hoạt động",
      href: "qc-policy",
    },
    {
      id: 4,
      title: "Liên kết bán hàng",
      href: "#",
    },
    {
      id: 5,
      title: "Điều khoản sử dụng",
      href: "dk-policy",
    },
    {
      id: 6,
      title: "Hỗ trợ khách hàng:",
      link: "contact.asisell@gmail.com",
      href: "mailto:contact.asisell@gmail.com",
    },
  ],
};

export { socials, moreInfo };

import clsx from "clsx";
import Image from "~/components/image/image";
import Button from "~/components/button/button";
import IconButton from "~/components/iconButton/iconButton";
import { formatNumber, convertCurrency } from "~/utils/common";

import styles from "./productComponent.module.scss";

function ProductComponent({
  className,
  image,
  brand,
  retailPrice,
  newPrice,
  discount,
  name,
  soldNumber,
  link,
  gifts,
  giftLink,
  isFreeShipping = false,
  isGifting = false,
  isCombo = false,
  isBestBuy = false,
}) {
  const giftImg = gifts[0] ? gifts[0].imgs[0] : "";
  const giftsNumber = gifts.length;
  const giftNames = gifts.map((gift) => gift.name).join(" + ");

  const GiftImgComponent = () => {
    return giftImg && <Image className={styles.giftImg} src={giftImg} />;
  };

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.content}>
        <div className={styles.imgContainer}>
          <IconButton className={styles.imgBtn} href={link}>
            <Image className={styles.img} src={image} />
          </IconButton>
          {isFreeShipping && (
            <div className={styles.freeShipping}>freeship</div>
          )}
          {discount > 0 && <div className={styles.discount}>-{discount}%</div>}
        </div>
        <div className={styles.mainContent}>
          <div className={styles.info}>
            <div className={styles.brandAndPrice}>
              <span className={styles.brand}>{brand}</span>
              <div className={styles.priceContainer}>
                <span
                  className={newPrice > 0 ? styles.retailPrice : styles.price}
                >
                  {convertCurrency(retailPrice)}
                </span>
                {newPrice > 0 && (
                  <span className={styles.price}>
                    {convertCurrency(newPrice)}
                  </span>
                )}
              </div>
            </div>
          </div>
          <Button className={styles.name} href={link} textBtn={name} />
          <div className={clsx(styles.sold, isBestBuy && styles.bestBuy)}>
            Đã bán{" "}
            <span className={styles.soldNumber}>
              {formatNumber(soldNumber)}
            </span>{" "}
            sản phẩm
          </div>
          {isGifting && giftsNumber === 1 && (
            <IconButton
              className={styles.gift}
              href={giftLink}
              textBtn={`Tặng ${giftsNumber} ${gifts[0].name}`}
            >
              <GiftImgComponent />
            </IconButton>
          )}
          {isCombo && giftsNumber > 1 && (
            <IconButton
              className={clsx(styles.gift, styles.combo)}
              href={giftLink}
              textBtn={`Tặng combo gồm ${giftNames}`}
            >
              <GiftImgComponent />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductComponent;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as userService from "~/services/userService";

export const getAll = createAsyncThunk(
  "user/getAll",
  async (params, thunkAPI) => {
    const currentUsers = await userService.getAll();
    return currentUsers;
  }
);

export const getUserById = createAsyncThunk(
  "user/getUserById",
  async (params, thunkAPI) => {
    const currentUsers = await userService.getUserById(params);
    return currentUsers;
  }
);

export const getUserByGoogleId = createAsyncThunk(
  "user/getUserByGoogleId",
  async (params, thunkAPI) => {
    const currentUsers = await userService.getUserByGoogleId(params);
    return currentUsers;
  }
);

export const addUser = createAsyncThunk("user/addUser", async (data) => {
  const currentUsers = await userService.addUser(data);
  return currentUsers;
});

export const editUserById = createAsyncThunk(
  "user/editUserById",
  async (data) => {
    const currentUsers = await userService.editUserById(data);
    return currentUsers;
  }
);

export const addDeliveryAddress = createAsyncThunk(
  "user/addDeliveryAddress",
  async (data) => {
    const currentUsers = await userService.addDeliveryAddress(data);
    return currentUsers;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUsers: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = false;
      state.currentUsers = action.payload;
    });

    builder.addCase(getUserById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserById.fulfilled, (state, action) => {
      state.loading = false;
      state.currentUsers = action.payload;
    });

    builder.addCase(getUserByGoogleId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserByGoogleId.fulfilled, (state, action) => {
      state.loading = false;
      state.currentUsers = action.payload;
    });

    builder.addCase(addUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.loading = false;
      state.currentUsers = action.payload;
    });

    builder.addCase(editUserById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editUserById.fulfilled, (state, action) => {
      state.loading = false;
      state.currentUsers = action.payload;
    });

    builder.addCase(addDeliveryAddress.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addDeliveryAddress.fulfilled, (state, action) => {
      state.loading = false;
      state.currentUsers = action.payload;
    });
  },
});

const { reducer: userReducer } = userSlice;

export default userReducer;

import CreditCard from "~/assets/images/credit-card.png";
import ZaloPay from "~/assets/images/zalo-pay.png";
import VnPay from "~/assets/images/vn-pay.png";
import MasterCard from "~/assets/images/master-card.png";
import Visa from "~/assets/images/visa.png";
import GHTK from "~/assets/images/giao-hang-tiet-kiem.png";
import GHN from "~/assets/images/giao-hang-nhanh.jpg";
import VietnamPost from "~/assets/images/vietnam-post.png";
import ViettelPost from "~/assets/images/viettel-post.png";
import JTExpress from "~/assets/images/jt-express.png";
import GrabExpress from "~/assets/images/grab-express.png";
import NinjaVan from "~/assets/images/ninja-van.png";
import BestExpress from "~/assets/images/best-express.png";
import Be from "~/assets/images/be.png";
import AhaMove from "~/assets/images/ahamove.png";
import PaymentMethods from "~/assets/images/payment_methods.png";
import DeliveryPartners from "~/assets/images/delivery_partners.png";
import bct from "~/assets/images/bct.png"

const paymentIcons = [
  {
    id: 1,
    icon: PaymentMethods,
  },
  
];

const deliveryIcons = [
  {
    id: 1,
    icon: DeliveryPartners,
  },
  
];
const BCT = [
  {
    id: 1,
    icon: bct,
  },
  
];



export { paymentIcons, deliveryIcons, BCT };

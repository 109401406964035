export default function FlagVNIcon({
  width = "2rem",
  height = "2rem",
  className,
  ...props
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      {...props}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_102_1332)">
        <path
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
          fill="#D80027"
        />
        <path
          d="M8.00002 4.17395L8.8634 6.83111H11.6573L9.39696 8.47329L10.2603 11.1305L8.00002 9.48826L5.73971 11.1305L6.60309 8.47329L4.34277 6.83111H7.13665L8.00002 4.17395Z"
          fill="#FFDA44"
        />
      </g>
      <defs>
        <clipPath id="clip0_102_1332">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as categoryService from "~/services/categoryService";

export const getAll = createAsyncThunk(
  "category/getAll",
  async (params, thunkAPI) => {
    const currentCategories = await categoryService.getAll();
    return currentCategories;
  }
);

export const getAllHasNoChildren = createAsyncThunk(
  "category/getAllHasNoChildren",
  async (params, thunkAPI) => {
    const currentCategories = await categoryService.getAllHasNoChildren();
    return currentCategories;
  }
);

export const getAllHasChildren = createAsyncThunk(
  "category/getAllHasChildren",
  async (params, thunkAPI) => {
    const currentCategories = await categoryService.getAllHasChildren();
    return currentCategories;
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState: {
    currentCategories: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = false;
      state.currentCategories = action.payload;
    });

    builder.addCase(getAllHasNoChildren.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllHasNoChildren.fulfilled, (state, action) => {
      state.loading = false;
      state.currentCategories = action.payload;
    });

    builder.addCase(getAllHasChildren.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllHasChildren.fulfilled, (state, action) => {
      state.loading = false;
      state.currentCategories = action.payload;
    });
  },
});

const { reducer: categoryReducer } = categorySlice;

export default categoryReducer;

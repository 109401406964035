import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ImageSlider from "../imageSlider/imageSlider";
import { fetchApi } from "~/utils/common";
import * as bannerSlice from "~/store/slice/bannerSlice";

function BannerSlider({ className }) {
  const [banners, setBanners] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const banner = () => fetchApi(bannerSlice.getAll(), dispatch);

    banner().then((result) => {
      setBanners(result[0].imgs);
    });
  }, []);

  return <ImageSlider className={className} imageList={banners} />;
}

export default BannerSlider;

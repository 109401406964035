import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import IconMenuBar from "~/components/iconMenuBar/iconMenuBar";
import { fetchApi, getIdFromPathName } from "~/utils/common";
import * as subCategorySlice from "~/store/slice/subCategorySlice";

function SubCategory({
  className,
  listStyles,
  itemStyles,
  setSubCateId,
  setFirstSubCateId,
}) {
  const [subCategory, setSubCategory] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const categoryId = getIdFromPathName();

    const getSubCategories = () =>
      fetchApi(subCategorySlice.getAllByCategory(categoryId), dispatch);

    getSubCategories().then((result) => {
      const subCates = result
        ? result.map((item) => ({ ...item, title: item.name }))
        : subCategory;
      setSubCategory(subCates);
      setFirstSubCateId(subCates[0] && subCates[0]._id);
    });
  }, []);

  return (
    <>
      {subCategory.length !== 0 && (
        <IconMenuBar
          menu={subCategory}
          title="Danh mục sản phẩm"
          className={className}
          listStyles={listStyles}
          itemStyles={itemStyles}
          setSubCateId={setSubCateId}
        />
      )}
    </>
  );
}

export default SubCategory;

import { useState, useEffect } from "react";
import clsx from "clsx";
import axios from "axios";
import { useDispatch } from "react-redux";
import Image from "~/components/image/image";
import Button from "~/components/button/button";
import IconButton from "~/components/iconButton/iconButton";
import { useGoogleLogin } from "@react-oauth/google";
import { fetchApi, getLocalGoogleId } from "~/utils/common";
import * as userSlice from "~/store/slice/userSlice";
import * as cartSlice from "~/store/slice/cartSlice";

import styles from "./login.module.scss";

function Login({ className, profileBtnStyles }) {
  const [user, setUser] = useState();

  const dispatch = useDispatch();

  const setGoogle = (googleId) => localStorage.setItem("googleId", googleId);
  const setIsLoggedIn = () => localStorage.setItem("isLoggedIn", true);
  const setCart = (cartId, productQuantity) => {
    localStorage.setItem(
      "cart",
      JSON.stringify({
        id: cartId,
        quantity: productQuantity ? productQuantity : 0,
      })
    );
  };

  const getUserByGoogleId = (googleId) =>
    fetchApi(userSlice.getUserByGoogleId(googleId), dispatch);
  const getCart = (cartId) => fetchApi(cartSlice.getCartById(cartId), dispatch);

  const setSessionUser = (googleId, cartId, productQuantity) => {
    setGoogle(googleId);
    setIsLoggedIn();
    setCart(cartId, productQuantity);
  };

  const setLocalUser = (user, isReload = false) => {
    setUser(user);
    getCart(user.cart).then((result) => {
      const cart = result;
      setSessionUser(user.google_id, cart._id, cart.products.length);
      isReload && window.location.reload();
    });
  };

  const signIn = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );

      const userData = userInfo.data;
      const nameArray = userData.name.split(" ");

      const user = {
        google_id: userData.sub,
        first_name: nameArray[0],
        last_name: nameArray[nameArray.length - 1],
        email: userData.email,
        avatar: userData.picture,
      };

      getUserByGoogleId(userData.sub).then((result) => {
        const currentUser = result[0];

        if (currentUser) {
          setLocalUser(currentUser, true);
        } else {
          const addUser = () => fetchApi(userSlice.addUser(user), dispatch);

          addUser().then((result) => {
            const user = result.newUser;
            const cart = result.newCart;

            setSessionUser(user.google_id, cart._id);
            window.location.reload();
          });
        }
      });
    },
    onError: (error) => console.log(error),
  });

  const localGoogleId = getLocalGoogleId();

  const handleGetUser = () => {
    if (localGoogleId) {
      getUserByGoogleId(localGoogleId).then((result) => {
        const user = result[0];
        setLocalUser(user);
      });
    }
  };

  const handleSignIn = () => {
    if (localGoogleId) {
      handleGetUser();
    } else {
      signIn();
    }
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");

    if (isLoggedIn === "true") {
      handleGetUser();
    } else {
      setUser(null);
    }
  }, []);

  return (
    <div className={clsx(styles.container, className)}>
      {user ? (
        <IconButton
          className={clsx(styles.btn, styles.profile, profileBtnStyles)}
          textBtn={user.name}
        >
          <Image
            className={clsx(styles.icon, styles.avatar)}
            src={user.avatar}
          />
        </IconButton>
      ) : (
        <Button
          className={styles.loginBtn}
          textBtn="Đăng nhập"
          onClick={handleSignIn}
        />
      )}
    </div>
  );
}

export default Login;

import React from "react";

// Main Pages
import HomePage from "~/pages/home/home";
// Products
const Products = React.lazy(() => import("~/pages/products/products"));
const productDetail = React.lazy(() =>
  import("~/pages/productDetail/productDetail")
);

// Profile
const Profile = React.lazy(() => import("~/pages/profile/profile"));

// Cart
const Cart = React.lazy(() => import("~/pages/cart/cart"));

// Checkout
const Checkout = React.lazy(() => import("~/pages/checkout/checkout"));

// Order
const OrderDetail = React.lazy(() => import("~/pages/orderDetail/orderDetail"));

// Successful Purchase
const SuccessfulPurchase = React.lazy(() =>
  import("~/pages/successfulPurchase/successfulPurchase")
);

// Info Pages
const AboutUs = React.lazy(() => import("~/pages/info/aboutUs/aboutUs"));

// Policy Pages
const SecurityPolicy = React.lazy(() =>
  import("~/pages/info/policies/security/security")
);
const DeliveryPolicy = React.lazy(() =>
  import("~/pages/info/policies/delivery/delivery")
);
const PromotionPolicy = React.lazy(() =>
  import("~/pages/info/policies/promotion/promotion")
);
const CustomerPolicy = React.lazy(() =>
  import("~/pages/info/policies/customer/customer")
);
const VnpayPolicy = React.lazy(() =>
  import("~/pages/info/policies/vnpay/vnpay")
);
const PaymentPolicy = React.lazy(() =>
  import("~/pages/info/policies/payment/payment")
);
const ReturnsPolicy = React.lazy(() =>
  import("~/pages/info/policies/returns/returns")
);
const DKPolicy = React.lazy(() =>
  import("~/pages/info/policies/dk/dk")
);
const DTPolicy = React.lazy(() =>
  import("~/pages/info/policies/dt/dt")
);
const GTPolicy = React.lazy(() =>
  import("~/pages/info/policies/gt/gt")
);
const QCPolicy = React.lazy(() =>
  import("~/pages/info/policies/qc/qc")
);

const publicRoutes = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/products/:CategoryName",
    component: Products,
  },
  {
    path: "/product/:productName",
    component: productDetail,
  },
  {
    path: "/cart",
    component: Cart,
  },
  {
    path: "/about-us",
    component: AboutUs,
  },
  {
    path: "/security-policy",
    component: SecurityPolicy,
  },
  {
    path: "/delivery-policy",
    component: DeliveryPolicy,
  },
  {
    path: "/promotion-policy",
    component: PromotionPolicy,
  },
  {
    path: "/customer-policy",
    component: CustomerPolicy,
  },
  {
    path: "/vnpay-policy",
    component: VnpayPolicy,
  },
  {
    path: "/payment-policy",
    component: PaymentPolicy,
  },
  {
    path: "/returns-policy",
    component: ReturnsPolicy,
  },
  {
    path: "/dt-policy",
    component: DTPolicy,
  },
  {
    path: "/dk-policy",
    component: DKPolicy,
  },
  {
    path: "/gt-policy",
    component:  GTPolicy,
  },
  {
    path: "/qc-policy",
    component: QCPolicy,
  },
];

const protectedRoutes = [
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/checkout",
    component: Checkout,
  },
  {
    path: "/successful-purchase",
    component: SuccessfulPurchase,
  },
  {
    path: "/order-detail/:orderId",
    component: OrderDetail,
  },
];

export { publicRoutes, protectedRoutes };

import clsx from "clsx";
import IconButton from "~/components/iconButton/iconButton";
import CartIcon from "~/assets/icons/cartIcon";

import styles from "./cartComponent.module.scss";

function CartComponent({ className }) {
  const localCart = JSON.parse(localStorage.getItem("cart"));

  return (
    <div className={clsx(styles.container, className)}>
      <IconButton className={styles.cartBtn} to="/cart">
        <CartIcon className={styles.cartIcon} />
        <div
          className={styles.cartNumber}
          style={{
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          }}
        >
          {localCart ? localCart.quantity : 0}
        </div>
      </IconButton>
    </div>
  );
}

export default CartComponent;

import { forwardRef } from "react";
import clsx from "clsx";
import Input from "~/components/input/input";

import styles from "./inputSection.module.scss";

const InputSection = forwardRef(
  (
    {
      className,
      inputStyles,
      title,
      type,
      placeholder,
      value,
      setState,
      children,
      rightChildren,
      onClickRightIcon,
      isDisabled = false,
    },
    ref
  ) => {
    return (
      <div className={clsx(styles.container, className)}>
        <span className={styles.title}>{title}</span>
        <Input
          className={clsx(styles.input, inputStyles)}
          ref={ref}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={(e) => setState(e.target.value)}
          rightChildren={rightChildren}
          onClickRightIcon={onClickRightIcon}
          isDisabled={isDisabled}
        >
          {children}
        </Input>
      </div>
    );
  }
);

export default InputSection;

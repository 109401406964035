import { useState, memo } from "react";
import clsx from "clsx";
import Button from "../button/button";
import Image from "../image/image";
import IconButton from "../iconButton/iconButton";
import Modal from "../modal/modal";
import BotChat from "~/assets/images/asi-bot.png";
import { qnas } from "./index";

import styles from "./qna.module.scss";

function QnA({ setIsShow, isShow }) {
  const [indexItem, setIndexItem] = useState();

  const [isHide, setIsHide] = useState(false);

  return (
    <div className={styles.container}>
      {!isHide && (
        <div className={styles.btnContainer}>
          <IconButton className={styles.qnaBtn} onClick={setIsShow}>
            <Image className={styles.qnaIcon} src={BotChat} />
          </IconButton>
          <IconButton
            className={styles.CloseBtn}
            onClick={() => setIsHide(true)}
          >
            <i className="fa-regular fa-circle-xmark"></i>
          </IconButton>
        </div>
      )}
      {isShow && (
        <Modal
          contentContainerStyles={indexItem && styles.contentHeight}
          title="Câu hỏi thường gặp"
          setIsShow={setIsShow}
        >
          {" "}
          <ul className={styles.list}>
            {qnas.map((qna, index) => {
              return (
                <li className={clsx(styles.item)} key={qna.id}>
                  <div
                    className={styles.titleContainer}
                    onClick={() => setIndexItem(index + 1)}
                  >
                    <Button className={styles.title} textBtn={qna.title} />
                    <IconButton className={styles.titleIcon}>
                      {indexItem === qna.id ? (
                        <i className="fa-solid fa-chevron-down"></i>
                      ) : (
                        <i className="fa-solid fa-chevron-up"></i>
                      )}
                    </IconButton>
                  </div>
                  {indexItem === qna.id &&
                    qna.subTitle.map((sub) => {
                      return (
                        <div className={styles.subtitleItem} key={sub.id}>
                          <p className={styles.subTitle}>{sub.title}</p>
                          {sub.content.map((cont) => {
                            return (
                              <p className={styles.contentItem} key={cont.id}>
                                {cont.text}
                              </p>
                            );
                          })}
                        </div>
                      );
                    })}
                </li>
              );
            })}
          </ul>
        </Modal>
      )}
    </div>
  );
}

export default memo(QnA);

export default function HomeIcon({
  width = "2rem",
  height = "2rem",
  className,
  ...props
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      {...props}
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.6667 23.0001V9.41506L14 3.41506L3.33335 9.41506V23.0001H8.66669V19.5626C8.66669 18.2365 9.22859 16.9647 10.2288 16.027C11.229 15.0893 12.5855 14.5626 14 14.5626C15.4145 14.5626 16.7711 15.0893 17.7713 16.027C18.7714 16.9647 19.3334 18.2365 19.3334 19.5626V23.0001H24.6667ZM16.6667 25.5001V19.5626C16.6667 18.8995 16.3857 18.2636 15.8856 17.7948C15.3855 17.326 14.7073 17.0626 14 17.0626C13.2928 17.0626 12.6145 17.326 12.1144 17.7948C11.6143 18.2636 11.3334 18.8995 11.3334 19.5626V25.5001H3.33335C2.62611 25.5001 1.94783 25.2367 1.44774 24.7678C0.947639 24.299 0.666687 23.6631 0.666687 23.0001V9.41506C0.666683 8.98335 0.785928 8.55899 1.01281 8.18331C1.23969 7.80763 1.56648 7.49343 1.96135 7.27131L12.628 1.27131C13.0425 1.03819 13.5167 0.915039 14 0.915039C14.4833 0.915039 14.9576 1.03819 15.372 1.27131L26.0387 7.27131C26.4336 7.49343 26.7603 7.80763 26.9872 8.18331C27.2141 8.55899 27.3334 8.98335 27.3334 9.41506V23.0001C27.3334 23.6631 27.0524 24.299 26.5523 24.7678C26.0522 25.2367 25.3739 25.5001 24.6667 25.5001H16.6667Z"
        fill="#21272A"
      />
    </svg>
  );
}

import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { fetchApi } from "~/utils/common";
import * as searchSlice from "~/store/slice/searchSlice";

import styles from "./searchResult.module.scss";
import generalStyles from "~/styles/generalStyle.module.scss";

function SearchResult() {
  const [searchResult, setSearchResult] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const search = () => fetchApi(searchSlice.getAll(), dispatch);

    search().then((result) => {
      const currentResult = [...result].sort((a, b) => b.count - a.count);

      setSearchResult(currentResult);
    });
  }, []);

  return (
    <div className={clsx(generalStyles.section, styles.container)}>
      <span className={clsx(generalStyles.title, styles.title)}>
        Tìm kiếm nhiều nhất
      </span>
      <ul className={clsx(generalStyles.list, styles.list, styles.searchList)}>
        {searchResult.map((search, index) => {
          return (
            <li className={clsx(styles.item, styles.searchItem)} key={index}>
              {search.key}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default SearchResult;

import React, { useState, useEffect } from "react";
import clsx from "clsx";
import ForYou from "../category/forYou/forYou";
import Filter from "../filter/filter";
import Category from "../category/category/category";
import SubCategory from "../category/subCategory/subCategory";
import SearchResult from "../search/searchResult/searchResult";

import styles from "./leftSide.module.scss";

function LeftSide({
  className,
  element,
  isFilter = false,
  isForYou = false,
  isCategory = false,
  isSearchResult = false,
  isSubCategory = false,
  setFilter,
  setSubCateId = () => {},
  setFirstSubCateId = () => {},
}) {
  const [htmlElement, setHtmlElement] = useState();
  const [containerElement, setContainerElement] = useState();

  const toggle = () => {
    const scrolled = htmlElement.scrollTop;

    if (scrolled > 300) {
      containerElement.classList.add(`${styles.fixedContainer}`);
    } else {
      containerElement.classList.remove(`${styles.fixedContainer}`);
    }
  };

  useEffect(() => {
    const container = document.querySelector(`.container`);
    const htmlEl = document.querySelector(`.${element}`);
    setContainerElement(container);
    setHtmlElement(htmlEl);

    if (htmlElement) {
      htmlElement.addEventListener("scroll", toggle);

      return () => htmlElement.removeEventListener("scroll", toggle);
    }
  }, [htmlElement]);

  const [chatWidth, setChatWidth] = useState(undefined);
  const [sidebarTop, setSidebarTop] = useState(undefined);

  useEffect(() => {
    const chatEl = document.querySelector(".container").getBoundingClientRect();
    setChatWidth(chatEl.width);
    setSidebarTop(chatEl.top);
  }, []);

  useEffect(() => {
    if (!sidebarTop) return;

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [sidebarTop]);

  const isSticky = (e) => {
    const chatEl = document.querySelector(".container");
    const scrollTop = htmlElement.scrollY;
    console.log("scrollTop", scrollTop);
    if (scrollTop >= sidebarTop - 10) {
      chatEl.classList.add(`${styles.fixedContainer}`);
    } else {
      chatEl.classList.remove(`${styles.fixedContainer}`);
    }
  };

  return (
    <div className={clsx(styles.container, "container", className)}>
      {isFilter && <Filter setFilter={setFilter} />}
      {isForYou && <ForYou />}
      {isCategory && <Category />}
      {isSubCategory && (
        <SubCategory
          setSubCateId={setSubCateId}
          setFirstSubCateId={setFirstSubCateId}
        />
      )}
      {isSearchResult && <SearchResult />}
    </div>
  );
}

export default LeftSide;

import httpRequest from "~/utils/httpRequest";

const pathName = "carts/";

export const getCartById = async (params) => {
  try {
    const res = await httpRequest.get(`${pathName}g/`, {
      params: {
        id: params,
      },
    });
    return res.data;
  } catch (err) {}
};

export const editCartById = async (data) => {
  try {
    const res = await httpRequest.put(`${pathName}u/`, data.body, {
      params: {
        id: data.id,
      },
    });
    return res.data;
  } catch (err) {}
};

export const deleteProduct = async (params) => {
  try {
    const res = await httpRequest.delete(`${pathName}d/`, {
      params: {
        id: params.id,
        product_id: params.product_id,
      },
    });
    return res.data;
  } catch (err) {}
};

export const deleteAllProducts = async (params) => {
  try {
    const res = await httpRequest.delete(`${pathName}d/`, {
      params: {
        id: params,
        type: "all_products",
      },
    });
    return res.data;
  } catch (err) {}
};

export const editProduct = async (data) => {
  try {
    const res = await httpRequest.put(`${pathName}u/`, data.body, {
      params: {
        id: data.id,
        product_id: data.product_id,
      },
    });
    return res.data;
  } catch (err) {}
};

export const addProduct = async (data) => {
  try {
    const res = await httpRequest.post(`${pathName}p/`, data.body, {
      params: {
        id: data.id,
        product_id: data.product_id,
      },
    });
    return res.data;
  } catch (err) {}
};

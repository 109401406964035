export default function InstagramIcon({
  width = "2rem",
  height = "2rem",
  className,
  ...props
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="instagram">
        <g id="instagram_2">
          <path
            d="M18.5334 6.8665C18.5334 7.6397 17.9066 8.2665 17.1334 8.2665C16.3602 8.2665 15.7334 7.6397 15.7334 6.8665C15.7334 6.09331 16.3602 5.4665 17.1334 5.4665C17.9066 5.4665 18.5334 6.09331 18.5334 6.8665Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0001 16.6665C14.5774 16.6665 16.6667 14.5772 16.6667 11.9998C16.6667 9.42251 14.5774 7.33317 12.0001 7.33317C9.42275 7.33317 7.33342 9.42251 7.33342 11.9998C7.33342 14.5772 9.42275 16.6665 12.0001 16.6665ZM12.0001 14.7998C13.5465 14.7998 14.8001 13.5462 14.8001 11.9998C14.8001 10.4534 13.5465 9.19984 12.0001 9.19984C10.4537 9.19984 9.20008 10.4534 9.20008 11.9998C9.20008 13.5462 10.4537 14.7998 12.0001 14.7998Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.66675 11.6265C2.66675 8.49021 2.66675 6.92206 3.27711 5.72416C3.814 4.67045 4.67069 3.81376 5.7244 3.27687C6.92231 2.6665 8.49045 2.6665 11.6267 2.6665H12.3734C15.5097 2.6665 17.0779 2.6665 18.2758 3.27687C19.3295 3.81376 20.1862 4.67045 20.7231 5.72416C21.3334 6.92206 21.3334 8.49021 21.3334 11.6265V12.3732C21.3334 15.5095 21.3334 17.0776 20.7231 18.2755C20.1862 19.3292 19.3295 20.1859 18.2758 20.7228C17.0779 21.3332 15.5097 21.3332 12.3734 21.3332H11.6267C8.49045 21.3332 6.92231 21.3332 5.7244 20.7228C4.67069 20.1859 3.814 19.3292 3.27711 18.2755C2.66675 17.0776 2.66675 15.5095 2.66675 12.3732V11.6265ZM11.6267 4.53317H12.3734C13.9724 4.53317 15.0593 4.53462 15.8995 4.60327C16.7179 4.67013 17.1364 4.79133 17.4283 4.94008C18.1308 5.29801 18.7019 5.86913 19.0598 6.57161C19.2086 6.86355 19.3298 7.28207 19.3967 8.10044C19.4653 8.94061 19.4667 10.0276 19.4667 11.6265V12.3732C19.4667 13.9721 19.4653 15.0591 19.3967 15.8992C19.3298 16.7176 19.2086 17.1361 19.0598 17.4281C18.7019 18.1305 18.1308 18.7017 17.4283 19.0596C17.1364 19.2084 16.7179 19.3295 15.8995 19.3964C15.0593 19.4651 13.9724 19.4665 12.3734 19.4665H11.6267C10.0278 19.4665 8.94085 19.4651 8.10069 19.3964C7.28231 19.3295 6.8638 19.2084 6.57185 19.0596C5.86938 18.7017 5.29825 18.1305 4.94032 17.4281C4.79157 17.1361 4.67037 16.7176 4.60351 15.8992C4.53487 15.0591 4.53341 13.9721 4.53341 12.3732V11.6265C4.53341 10.0276 4.53487 8.94061 4.60351 8.10044C4.67037 7.28207 4.79157 6.86355 4.94032 6.57161C5.29825 5.86913 5.86938 5.29801 6.57185 4.94008C6.8638 4.79133 7.28231 4.67013 8.10069 4.60327C8.94085 4.53462 10.0278 4.53317 11.6267 4.53317Z"
            fill="black"
          />
        </g>
      </g>
    </svg>
  );
}

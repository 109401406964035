import { useState } from "react";
import clsx from "clsx";
import CheckBox from "../checkBox/checkBox";
import { filter } from "./index";

import styles from "./filter.module.scss";
import generalStyles from "~/styles/generalStyle.module.scss";

function Filter({ setFilter }) {
  const [indexItem, setIndexItem] = useState();

  return (
    <div className={clsx(generalStyles.section, styles.container)}>
      <span className={styles.title}>Bộ lọc sản phẩm</span>
      <ul className={clsx(generalStyles.list, styles.filterContainer)}>
        {filter.map((item, index) => {
          return (
            <CheckBox
              className={styles.filter}
              title={item.title}
              isChecked={indexItem === index + 1}
              onClick={() => setFilter(item.filter)}
              onChange={() => setIndexItem(item.id)}
              key={item.id}
            />
          );
        })}
      </ul>
    </div>
  );
}

export default Filter;

export const infos = [
  {
    id: 1,
    title: "Trụ sở chính:",
    text: "L17-11, Tầng 17, Tòa nhà Vincom Center, 72 Lê Thánh Tôn, P. Bến Nghé, Q.1, TP. HCM.",
  },
  {
    id: 2,
    title: "Hotline:",
    text: "0919 111 419",
  },
  {
    id: 3,
    text: "Giấy chứng nhận Đăng ký Kinh doanh số 0317020213 do Sở Kế hoạch và Đầu tư Thành phố Hồ Chí Minh cấp lần đầu ngày 10/11/2021.",
  },
  {
    id: 4,
    title: "Asisell",
    text: "nhận đặt hàng trực tuyến và giao hàng tận nơi, chưa hỗ trợ mua và nhận hàng trực tiếp tại văn phòng hoặc trung tâm xử lý đơn hàng",
  },
  
  {
    id: 5,
    text: "© 2023 - Bản quyền của Công ty TNHH Vinipr Media.",
  },
  
];

export default function CartIcon({
  width = "2rem",
  height = "2rem",
  className,
  ...props
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      {...props}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6607 7.5C20.6373 7.39913 20.613 7.29803 20.5877 7.19683C20.2577 5.87672 19.738 4.44228 18.8906 3.3125C18.0169 2.14752 16.7411 1.25 15 1.25C13.2589 1.25 11.9831 2.14752 11.1094 3.3125C10.262 4.44228 9.74236 5.87672 9.41233 7.19683C9.38703 7.29803 9.36271 7.39913 9.33932 7.5H7.33889C5.36465 7.5 3.72852 9.03069 3.5972 11.0006L2.68053 24.7506C2.53624 26.915 4.25299 28.75 6.42223 28.75H23.5778C25.747 28.75 27.4638 26.915 27.3195 24.7506L26.4028 11.0006C26.2715 9.03069 24.6354 7.5 22.6611 7.5H20.6607ZM10 13.75C10.7162 13.75 11.2476 13.2105 11.25 12.4977C11.2502 12.468 11.2506 12.4383 11.2511 12.4085C11.2522 12.3427 11.2544 12.2432 11.259 12.1149C11.2682 11.8581 11.2866 11.4875 11.3238 11.0413C11.3502 10.7247 11.3857 10.3733 11.4334 10H18.5666C18.6143 10.3733 18.6498 10.7247 18.6762 11.0413C18.7134 11.4875 18.7319 11.8581 18.741 12.1149C18.7456 12.2432 18.7479 12.3427 18.749 12.4085C18.7495 12.4392 18.7499 12.4698 18.75 12.5005C18.75 12.5005 18.75 13.75 20 13.75C21.25 13.75 21.25 12.4983 21.25 12.4983C21.2499 12.4545 21.2493 12.4107 21.2486 12.3669C21.2473 12.2862 21.2446 12.1709 21.2394 12.0257C21.2291 11.7356 21.2085 11.325 21.1676 10.8337C21.1462 10.5775 21.1192 10.2977 21.0849 10H22.6611C23.3192 10 23.8646 10.5102 23.9084 11.1669L24.825 24.9169C24.8731 25.6383 24.3009 26.25 23.5778 26.25H6.42223C5.69915 26.25 5.1269 25.6383 5.175 24.9168L6.09166 11.1669C6.13544 10.5102 6.68081 10 7.33889 10H8.91511C8.88083 10.2977 8.8538 10.5775 8.83246 10.8337C8.79151 11.325 8.77094 11.7356 8.76058 12.0257C8.75539 12.1709 8.75275 12.2862 8.75141 12.3669C8.75069 12.4098 8.75015 12.4528 8.75002 12.4957C8.74906 13.209 9.28543 13.75 10 13.75ZM11.9169 7.5H18.0831C17.7941 6.44152 17.4013 5.49332 16.8906 4.8125C16.3581 4.10248 15.7589 3.75 15 3.75C14.2411 3.75 13.6419 4.10248 13.1094 4.8125C12.5988 5.49332 12.2059 6.44152 11.9169 7.5Z"
        fill="#00A2FF"
      />
    </svg>
  );
}

const homeIcon = <i className="fa-solid fa-house"></i>;
const userIcon = <i className="fa-solid fa-user"></i>;

export const menu = [
  {
    id: 1,
    title: "Trang cá nhân",
    icon: userIcon,
    href: "/profile",
  },
  {
    id: 2,
    title: "Trang chủ",
    icon: homeIcon,
    href: "/",
  },
];

import moment from "moment";
import vi from "moment/locale/vi";
import { unwrapResult } from "@reduxjs/toolkit";

// Number formatting
const formatNumber = (num) => {
  const regex = /(\d)(?=(\d{3})+(?!\d))/g;

  const number = num ? num : 0;

  return number.toString().replace(regex, "$1,");
};

// Convert Currency
const convertCurrency = (currency) => {
  const currencyUnit = "đ";

  return `${formatNumber(currency)} ${currencyUnit}`;
};

// Convert Date
const convertDate = (date) => {
  const time = date.toLocaleString().split(",")[0].split("/");
  let day = time[1];
  let month = time[0];
  let year = time[2];

  if (!day.startsWith("0")) {
    day = day < 10 ? "0" + day : day;
  }

  if (!month.startsWith("0")) {
    month = month < 10 ? "0" + month : month;
  }

  return day + "/" + month + "/" + year;
};

// Convert Time
const convertTime = (time) => {
  const currentTime = moment(time)
    .locale("vi", vi)
    .format("DD/MM/YYYY hh:mm A");

  return currentTime;
};

// Get timestamps
const getTimestamps = (date) => {
  const timestamps = new Date(date).getTime();

  return timestamps;
};

// Sort List By Time
const sortListByTime = (list, setList) => {
  const sortedList = [...list].sort(
    (a, b) => getTimestamps(b.date) - getTimestamps(a.date)
  );

  setList(sortedList);
};

// Fetch API
const fetchApi = async (api, dispatch) => {
  try {
    const action = api;
    const actionResult = await dispatch(action);
    const result = unwrapResult(actionResult);
    return result;
  } catch (error) {
    console.log(error.message);
  }
};

// Upload file
const handleFile = (value, setValue) => {
  const file = value.target.files[0];

  file.fileOpened = URL.createObjectURL(file);

  setValue({ file: file.fileOpened, fileName: file.name });
};

// White Space
const hasWhiteSpace = (s) => {
  return /\s/g.test(s);
};

// Get Id From Path Name
const getIdFromPathName = () => {
  const pathNameArray = window.location.pathname.split("/");
  const id = pathNameArray[pathNameArray.length - 1];
  return id;
};

// Get Local Google Id
const getLocalGoogleId = () => {
  const localGoogleId = localStorage.getItem("googleId");

  return localGoogleId;
};

// Get Local Category Id
const getLocalCategoryId = () => {
  const localCategoryId = localStorage.getItem("categoryId");

  return localCategoryId;
};

// Set Document Title
const setDocumentTitle = (title) => {
  return (document.title = `${title} | asisell`);
};

export {
  formatNumber,
  convertCurrency,
  convertDate,
  convertTime,
  getTimestamps,
  sortListByTime,
  fetchApi,
  handleFile,
  hasWhiteSpace,
  getIdFromPathName,
  getLocalGoogleId,
  getLocalCategoryId,
  setDocumentTitle,
};

import clsx from "clsx";
import Image from "../../image/image";
import Button from "~/components/button/button";
import ProductComponent from "../productComponent/productComponent";

import styles from "./productSection.module.scss";
import generalStyles from "~/styles/generalStyle.module.scss";

function ProductSection({
  list,
  moreProducts,
  isFreeShipping,
  isGifting,
  isCombo,
  isBestBuy,
  className,
  listStyles,
  itemStyles,
}) {
  return (
    <div className={styles.container}>
      <div className={clsx(generalStyles.section, styles.section, className)}>
        {list.title && (
          <div className={styles.header}>
            <div className={styles.titleContainer}>
              <Image className={styles.titleIcon} src={list.icon} />
              <span className={generalStyles.title}>{list.title}</span>
            </div>
            
          </div>
        )}
        <ul
          className={clsx(generalStyles.list, styles.productList, listStyles)}
        >
          {list.products &&
            list.products.map((product, index) => {
              return (
                <ProductComponent
                  className={clsx(styles.productItem, itemStyles)}
                  image={product.images[0]}
                  brand={product.brand}
                  retailPrice={product.retailPrice}
                  newPrice={product.new_price}
                  discount={product.discount}
                  name={product.name}
                  soldNumber={product.soldNumber}
                  link={product.link}
                  gifts={product.gifts}
                  isFreeShipping={isFreeShipping}
                  isGifting={isGifting}
                  isCombo={isCombo}
                  isBestBuy={isBestBuy}
                  key={index}
                />
              );
            })}
            {list.products && list.products.length > 5 && (
              <Button
                className={styles.moreBtn}
                href={moreProducts}
                textBtn="Xem thêm"
                textStyles={styles.moreText}
              >
                <i className="fa-solid fa-angle-right"></i>
              </Button>
            )}
        </ul>
      </div>
    </div>
  );
}

export default ProductSection;

import { useState, useEffect } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import LeftSide from "~/components/leftSide/leftSide.jsx";
import Header from "~/components/header/header";
import Footer from "~/components/footer/footer";
import QnA from "~/components/qna/qna";
import Category from "~/components/category/category/category";
import Loading from "~/components/loading/loading";
import BannerSlider from "~/components/bannerSlider/bannerSlider";
import ProductSection from "~/components/product/productSection/productSection";
import ScrollToTopButton from "~/components/scrollToTopButton/scrollToTopButton";
import FlashSale from "~/assets/icons/flash-sale.png";
import BonusOffer from "~/assets/icons/bonus-offer.png";
import BestBuy from "~/assets/icons/best-buy.png";
import Combo from "~/assets/icons/combo.png";
import Recommendation from "~/assets/icons/recommendation.png";
import { fetchApi, getLocalCategoryId } from "~/utils/common";
import * as productSlice from "~/store/slice/productSlice";

import styles from "./home.module.scss";
import generalStyles from "~/styles/generalStyle.module.scss";

function HomePage() {
  const [bonusOffer, setBonusOffer] = useState();
  const [bestBuy, setBestBuy] = useState();
  const [combo, setCombo] = useState();
  const [recommendation, setRecommendation] = useState();
  const [isShow, setIsShow] = useState(false);

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.product.loading);

  const handleShowModal = () => {
    isShow ? setIsShow(false) : setIsShow(true);
  };

  const handleProducts = (result, limitNumber) => {
    let products = result.map((item) => ({
      images: item.imgs,
      brand: item.brand && item.brand.name,
      retailPrice: item.retail_price,
      newPrice: item.new_price,
      discount: item.discount,
      name: item.name,
      soldNumber: item.sold,
      link: `/product/${item._id}`,
      gifts: item.gifts,
    }));

    products = products.filter((product, index) => index < limitNumber);
    return products;
  };

  useEffect(() => {
    document.title = "Asisell | Nền tảng nông sản Việt Nam";

    const localCategoryId = getLocalCategoryId();

    const bonusOffer = () =>
      fetchApi(productSlice.getAllWithOneGift(), dispatch);
    const combo = () => fetchApi(productSlice.getAllFromTwoGifts(), dispatch);
    const getAllProducts = () =>
      fetchApi(productSlice.getAllProducts(), dispatch);

    bonusOffer().then((result) => {
      const bonusOffer = {
        title: "Ưu đãi tặng kèm",
        icon: BonusOffer,
        products: handleProducts(result, 5),
        moreProducts: `/products/${localCategoryId}`,
      };
      setBonusOffer(bonusOffer);
    });

    combo().then((result) => {
      const combo = {
        title: "Combo siêu ưu đãi",
        icon: Combo,
        products: handleProducts(result, 10),
        moreProducts: `/products/${localCategoryId}`,
      };
      setCombo(combo);
    });

    getAllProducts().then((result) => {
      const bestBuy = {
        title: "Mua nhiều nhất",
        icon: BestBuy,
        products: handleProducts(result, 15),
        moreProducts: `/products/${localCategoryId}`,
      };
      const recommendation = {
        title: "Gợi ý cho bạn",
        icon: Recommendation,
        products: handleProducts(result, 15),
        moreProducts: `/products/${localCategoryId}`,
      };

      setBestBuy(bestBuy);
      setRecommendation(recommendation);
    });
  }, []);

  return (
    <div className={clsx(generalStyles.generalContainer, styles.container)}>
      <Header />
      {false ? (
        <Loading />
      ) : (
        <div className={generalStyles.body}>
          <div className={clsx(generalStyles.content, styles.bodyContainer)}>
            <LeftSide
              className={styles.leftSide}
              element={styles.container}
              isForYou={true}
              isSubCategory={false}
              isSearchResult={true}
            />
            <div className={styles.contentContainer}>
              <div className={styles.content}>
                <div>
                  <div className={styles.header}>
                    <BannerSlider />
                    {/* <BannerSlider
                      className={styles.smallBanner}
                      indexArrayImgs={1}
                    /> */}
                  </div>
                  <Category
                    className={generalStyles.mobileCategory}
                    listStyles={generalStyles.mobileCateList}
                    itemStyles={generalStyles.mobileCateItem}
                  />
                  <div className={styles.body}>
                    {bonusOffer && (
                      <ProductSection
                        list={bonusOffer}
                        moreProducts={bonusOffer.moreProducts}
                        isFreeShipping={true}
                        isGifting={true}
                      />
                    )}

                    {combo && (
                      <ProductSection
                        list={combo}
                        moreProducts={combo.moreProducts}
                        isFreeShipping={true}
                        isCombo={true}
                      />
                    )}

                    {bestBuy && (
                      <ProductSection
                        list={bestBuy}
                        moreProducts={bestBuy.moreProducts}
                        isFreeShipping={true}
                        isBestBuy={true}
                      />
                    )}
                    <BannerSlider />
                    {recommendation && (
                      <ProductSection
                        list={recommendation}
                        moreProducts={recommendation.moreProducts}
                        isFreeShipping={true}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <QnA setIsShow={handleShowModal} isShow={isShow} />
      <Footer />
      <ScrollToTopButton element={styles.container} />
    </div>
  );
}

export default HomePage;

import clsx from "clsx";
import IconButton from "~/components/iconButton/iconButton";

import styles from "./logout.module.scss";

function Logout({ className }) {
  const logOut = () => {
    localStorage.setItem("isLoggedIn", false);
    localStorage.removeItem("googleId");
    localStorage.removeItem("cart");
    window.location.reload();
  };

  return (
    <>
      <IconButton
        className={clsx(styles.btn, className)}
        textBtn="Đăng xuất"
        onClick={logOut}
      >
        <i className="fa-solid fa-right-from-bracket"></i>
      </IconButton>
    </>
  );
}

export default Logout;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as orderService from "~/services/orderService";

export const getAll = createAsyncThunk(
  "order/getAll",
  async (params, thunkAPI) => {
    const currentOrders = await orderService.getAll();
    return currentOrders;
  }
);

export const getAllByPending = createAsyncThunk(
  "order/getAllByPending",
  async (params, thunkAPI) => {
    const currentOrders = await orderService.getAllByPending();
    return currentOrders;
  }
);

export const getAllBySuccess = createAsyncThunk(
  "order/getAllBySuccess",
  async (params, thunkAPI) => {
    const currentOrders = await orderService.getAllBySuccess();
    return currentOrders;
  }
);

export const getAllByCancel = createAsyncThunk(
  "order/getAllByCancel",
  async (params, thunkAPI) => {
    const currentOrders = await orderService.getAllByCancel();
    return currentOrders;
  }
);

export const getOrderById = createAsyncThunk(
  "order/getOrderById",
  async (params, thunkAPI) => {
    const currentOrders = await orderService.getOrderById(params);
    return currentOrders;
  }
);

export const getOrderByUser = createAsyncThunk(
  "order/getOrderByUser",
  async (params, thunkAPI) => {
    const currentOrders = await orderService.getOrderByUser(params);
    return currentOrders;
  }
);

export const addOrder = createAsyncThunk(
  "order/addOrder",
  async (params, thunkAPI) => {
    const currentOrders = await orderService.addOrder(params);
    return currentOrders;
  }
);

export const editOrder = createAsyncThunk(
  "order/editOrder",
  async (params, thunkAPI) => {
    const currentOrders = await orderService.editOrder(params);
    return currentOrders;
  }
);

export const deleteOrderById = createAsyncThunk(
  "order/deleteOrderById",
  async (params, thunkAPI) => {
    const currentOrders = await orderService.deleteOrderById(params);
    return currentOrders;
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState: {
    currentOrders: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.loading = false;
      state.currentOrders = action.payload;
    });

    builder.addCase(getAllByPending.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllByPending.fulfilled, (state, action) => {
      state.loading = false;
      state.currentOrders = action.payload;
    });

    builder.addCase(getAllBySuccess.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllBySuccess.fulfilled, (state, action) => {
      state.loading = false;
      state.currentOrders = action.payload;
    });

    builder.addCase(getAllByCancel.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllByCancel.fulfilled, (state, action) => {
      state.loading = false;
      state.currentOrders = action.payload;
    });

    builder.addCase(getOrderById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrderById.fulfilled, (state, action) => {
      state.loading = false;
      state.currentOrders = action.payload;
    });

    builder.addCase(getOrderByUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrderByUser.fulfilled, (state, action) => {
      state.loading = false;
      state.currentOrders = action.payload;
    });

    builder.addCase(addOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.currentOrders = action.payload;
    });

    builder.addCase(editOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.currentOrders = action.payload;
    });

    builder.addCase(deleteOrderById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteOrderById.fulfilled, (state, action) => {
      state.loading = false;
      state.currentOrders = action.payload;
    });
  },
});

const { reducer: orderReducer } = orderSlice;

export default orderReducer;

import clsx from "clsx";
import { infos } from "./index";

import styles from "./partFour.module.scss";
import generalStyles from "~/styles/generalStyle.module.scss";

function PartFour() {
  return (
    <div
      className={clsx(generalStyles.footerSectionContainer, styles.container)}
    >
      <div className={clsx(generalStyles.body, styles.content)}>
        <span className={clsx(generalStyles.title, styles.title)}>
          {infos.title}
        </span>
        {infos.texts.map((text, index) => (
          <span className={styles.text} key={index}>
            {text}
          </span>
        ))}
      </div>
    </div>
  );
}

export default PartFour;

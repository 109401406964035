import clsx from "clsx";
import Image from "~/components/image/image";
import Button from "~/components/button/button";

import styles from "./searchItem.module.scss";

function SearchItem({ data }) {
  const gifts = data.gifts;
  const giftsNumber = gifts.length;
  const giftNames = gifts.map((gift) => gift.name).join(" + ");

  return (
    <div className={styles.container}>
      <Image className={styles.img} src={data.imgs[0]} />
      <div className={styles.content}>
        <Button
          className={styles.name}
          href={`product/${data._id}`}
          textBtn={data.name}
        />
        {gifts.length !== 0 && (
          <span
            className={clsx(
              styles.gift,
              giftsNumber === 1 ? styles.giftColor : styles.comboColor
            )}
          >
            {giftNames}
          </span>
        )}
      </div>
    </div>
  );
}

export default SearchItem;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as cartService from "~/services/cartService";

export const getCartById = createAsyncThunk(
  "cart/getCartById",
  async (params, thunkAPI) => {
    const currentCart = await cartService.getCartById(params);
    return currentCart;
  }
);

export const editCartById = createAsyncThunk(
  "cart/editCartById",
  async (params, thunkAPI) => {
    const currentCart = await cartService.editCartById(params);
    return currentCart;
  }
);

export const editProduct = createAsyncThunk(
  "cart/editProduct",
  async (params, thunkAPI) => {
    const currentCart = await cartService.editProduct(params);
    return currentCart;
  }
);

export const deleteProduct = createAsyncThunk(
  "cart/deleteProduct",
  async (params, thunkAPI) => {
    const currentCart = await cartService.deleteProduct(params);
    return currentCart;
  }
);

export const deleteAllProducts = createAsyncThunk(
  "cart/deleteAllProducts",
  async (params, thunkAPI) => {
    const currentCart = await cartService.deleteAllProducts(params);
    return currentCart;
  }
);

export const addProduct = createAsyncThunk("cart/addProduct", async (data) => {
  const currentCart = await cartService.addProduct(data);
  return currentCart;
});

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    currentCart: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCartById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCartById.fulfilled, (state, action) => {
      state.loading = false;
      state.currentCart = action.payload;
    });

    builder.addCase(editCartById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editCartById.fulfilled, (state, action) => {
      state.loading = false;
      state.currentCart = action.payload;
    });

    builder.addCase(editProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editProduct.fulfilled, (state, action) => {
      state.loading = false;
      state.currentCart = action.payload;
    });

    builder.addCase(deleteProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteProduct.fulfilled, (state, action) => {
      state.loading = false;
      state.currentCart = action.payload;
    });

    builder.addCase(deleteAllProducts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAllProducts.fulfilled, (state, action) => {
      state.loading = false;
      state.currentCart = action.payload;
    });

    builder.addCase(addProduct.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addProduct.fulfilled, (state, action) => {
      state.loading = false;
      state.currentCart = action.payload;
    });
  },
});

const { reducer: cartReducer } = cartSlice;

export default cartReducer;

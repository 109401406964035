export default function TikTokIcon({
  width = "2rem",
  height = "2rem",
  className,
  ...props
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="tiktok">
        <path
          id="vector"
          d="M6.33822 14.8444C6.45542 13.8739 6.85342 13.3306 7.60344 12.7736C8.67659 12.0193 10.0171 12.446 10.0171 12.446V9.91462C10.343 9.90629 10.669 9.92561 10.9914 9.97234V13.23C10.9914 13.23 9.65125 12.8033 8.5781 13.558C7.82849 14.1146 7.42967 14.6583 7.31288 15.6287C7.30921 16.1557 7.4081 16.8445 7.86349 17.4401C7.7509 17.3824 7.63614 17.3166 7.5192 17.2427C6.51606 16.5691 6.33333 15.5585 6.33822 14.8444ZM16.5264 5.23411C15.7881 4.42517 15.509 3.60842 15.4081 3.03467H16.3367C16.3367 3.03467 16.1516 4.53906 17.501 6.01849L17.5197 6.03838C17.156 5.8096 16.8225 5.53956 16.5264 5.23411ZM21 7.52756V10.7197C21 10.7197 19.815 10.6733 18.938 10.4501C17.7135 10.1381 16.9264 9.65953 16.9264 9.65953C16.9264 9.65953 16.3827 9.31824 16.3388 9.29445V15.8862C16.3388 16.2532 16.2382 17.1698 15.9318 17.9343C15.5318 18.9343 14.9144 19.5908 14.8009 19.725C14.8009 19.725 14.05 20.6123 12.7254 21.2098C11.5314 21.7489 10.4831 21.7352 10.1697 21.7489C10.1697 21.7489 8.35753 21.8207 6.72686 20.7609C6.37424 20.5273 6.04508 20.2628 5.74365 19.9707L5.75179 19.9765C7.38287 21.0363 9.19464 20.9645 9.19464 20.9645C9.5084 20.9509 10.5567 20.9645 11.7503 20.4255C13.0738 19.8279 13.8258 18.9406 13.8258 18.9406C13.9381 18.8064 14.5583 18.15 14.9567 17.1495C15.2624 16.3854 15.3637 15.4684 15.3637 15.1014V8.51047C15.4076 8.53465 15.9509 8.87594 15.9509 8.87594C15.9509 8.87594 16.7384 9.35491 17.9629 9.66655C18.8403 9.88966 20.025 9.93607 20.025 9.93607V7.43473C20.4303 7.52561 20.7758 7.55018 21 7.52756Z"
          fill="black"
        />
        <path
          id="vector_2"
          d="M20.0254 7.43473V9.93529C20.0254 9.93529 18.8408 9.88887 17.9634 9.66577C16.7388 9.35374 15.9514 8.87516 15.9514 8.87516C15.9514 8.87516 15.4081 8.53387 15.3641 8.50969V15.1022C15.3641 15.4692 15.2636 16.3862 14.9572 17.1503C14.5571 18.1507 13.9398 18.8072 13.8262 18.9414C13.8262 18.9414 13.075 19.8287 11.7508 20.4262C10.5572 20.9653 9.50884 20.9516 9.19507 20.9653C9.19507 20.9653 7.3833 21.0371 5.75222 19.9773L5.74408 19.9715C5.57187 19.8047 5.40978 19.6286 5.25858 19.4441C4.73808 18.8095 4.41903 18.0591 4.33886 17.845C4.33872 17.844 4.33872 17.8431 4.33886 17.8422C4.20986 17.4701 3.93882 16.5765 3.97586 15.711C4.04138 14.184 4.57856 13.2467 4.72059 13.0119C5.09674 12.3716 5.58597 11.7987 6.1665 11.3188C6.67879 10.9046 7.25945 10.5751 7.88467 10.3437C8.56056 10.072 9.28431 9.92637 10.0171 9.91462V12.446C10.0171 12.446 8.67661 12.0208 7.60387 12.7736C6.85385 13.3306 6.45585 13.8739 6.33864 14.8444C6.33376 15.5585 6.51648 16.5691 7.51882 17.2431C7.63575 17.3172 7.75051 17.383 7.8631 17.4405C8.03821 17.6681 8.25134 17.8664 8.49389 18.0275C9.47302 18.6473 10.2934 18.6906 11.3426 18.288C12.0421 18.0189 12.5687 17.4124 12.8129 16.7404C12.9663 16.3207 12.9643 15.8982 12.9643 15.4614V3.03467H15.406C15.507 3.60842 15.7861 4.42517 16.5244 5.23411C16.8205 5.53956 17.154 5.8096 17.5177 6.03838C17.6252 6.14954 18.1746 6.69911 18.8798 7.0365C19.2445 7.21089 19.6289 7.34452 20.0254 7.43473V7.43473Z"
          fill="black"
        />
        <path
          id="vector_3"
          d="M3.36694 17.0679V17.0698L3.42753 17.234C3.42057 17.2149 3.39806 17.1568 3.36694 17.0679Z"
          fill="black"
        />
        <path
          id="vector_4"
          d="M7.88461 10.344C7.25939 10.5754 6.67873 10.9049 6.16644 11.3191C5.58572 11.8001 5.09661 12.3742 4.72094 13.0158C4.57891 13.2498 4.04172 14.1878 3.9762 15.7149C3.93917 16.5804 4.2102 17.4739 4.33921 17.846C4.33907 17.847 4.33907 17.8479 4.33921 17.8488C4.4206 18.061 4.73843 18.8114 5.25893 19.4479C5.41012 19.6325 5.57222 19.8085 5.74443 19.9753C5.19268 19.6095 4.7006 19.1674 4.28427 18.6632C3.76825 18.034 3.45001 17.2914 3.36699 17.0722C3.36689 17.0706 3.36689 17.0691 3.36699 17.0675V17.0648C3.23758 16.6931 2.96573 15.7991 3.00358 14.9324C3.0691 13.4054 3.60628 12.4681 3.74831 12.2333C4.12388 11.5917 4.613 11.0176 5.19382 10.5367C5.706 10.1223 6.28669 9.79279 6.91199 9.56155C7.30202 9.40646 7.70833 9.29203 8.1239 9.22027C8.75019 9.11549 9.38984 9.1064 10.0191 9.19336V9.91493C9.2856 9.92643 8.56113 10.0721 7.88461 10.344Z"
          fill="black"
        />
        <path
          id="vector_5"
          d="M15.4081 3.03476H12.9664V15.4619C12.9664 15.8987 12.9664 16.32 12.815 16.7408C12.5683 17.4125 12.0438 18.019 11.3446 18.2881C10.2951 18.6922 9.47467 18.6474 8.49594 18.0276C8.25297 17.8673 8.03929 17.6696 7.86353 17.4425C8.69738 17.8688 9.44374 17.8614 10.3683 17.5057C11.0671 17.2366 11.5925 16.6301 11.8383 15.958C11.9921 15.5383 11.9901 15.1159 11.9901 14.6795V2.25H15.3617C15.3617 2.25 15.3238 2.55891 15.4081 3.03476ZM20.0254 6.74328V7.43483C19.6296 7.34447 19.2459 7.21085 18.8819 7.0366C18.1766 6.69921 17.6272 6.14964 17.5198 6.03848C17.6445 6.11693 17.7738 6.1883 17.9072 6.25222C18.7647 6.66255 19.6091 6.78502 20.0254 6.74328Z"
          fill="black"
        />
      </g>
    </svg>
  );
}

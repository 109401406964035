import { useState, useEffect } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import Button from "~/components/button/button";
import { fetchApi, getLocalCategoryId } from "~/utils/common";
import * as subCategory from "~/store/slice/subCategorySlice";

import styles from "./partFive.module.scss";
import generalStyles from "~/styles/generalStyle.module.scss";

function PartFive() {
  const [subCategories, setSubCategories] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const getAll = () => fetchApi(subCategory.getAll(), dispatch);

    const subCateList = (result) =>
      result.map((item) => ({
        name: item.name,
        link: `/products/${getLocalCategoryId()}`,
      }));

    getAll().then((result) => setSubCategories(subCateList(result)));
  }, []);

  return (
    <div
      className={clsx(generalStyles.footerSectionContainer, styles.container)}
    >
      <div className={clsx(generalStyles.body, styles.content)}>
        <span className={clsx(generalStyles.title, styles.title)}>
          Danh mục sản phẩm
        </span>
        <ul className={clsx(generalStyles.list, styles.list)}>
          {subCategories.map((cate, index) => {
            return (
              <li className={styles.item} key={index}>
                <Button
                  className={styles.btn}
                  href={cate.link}
                  textBtn={cate.name}
                />
                <span className={styles.separator}>&ensp;|&ensp;</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default PartFive;

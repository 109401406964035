export default function SearchIcon({
  width = "2rem",
  height = "2rem",
  className,
  ...props
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      {...props}
      viewBox="0 0 40 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3985 22.7855C30.4522 20.5391 31.6802 17.6897 31.6802 14.5893C31.6802 7.33725 24.9614 1.45831 16.6733 1.45831C8.3853 1.45831 1.6665 7.33725 1.6665 14.5893C1.6665 21.8413 8.3853 27.7203 16.6733 27.7203C20.2167 27.7203 23.4731 26.6458 26.0404 24.8488L35.4867 33.1143C36.1379 33.6841 37.1936 33.6841 37.8448 33.1143C38.496 32.5445 38.496 31.6208 37.8448 31.051L28.3985 22.7855ZM16.6733 24.8169C10.2178 24.8169 4.98463 20.2379 4.98463 14.5893C4.98463 8.94073 10.2178 4.36167 16.6733 4.36167C23.1288 4.36167 28.3621 8.94073 28.3621 14.5893C28.3621 20.2379 23.1288 24.8169 16.6733 24.8169Z"
        fill="#797272"
      />
    </svg>
  );
}

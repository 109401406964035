import { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import HeadlessTippy from "@tippyjs/react/headless";
import Input from "../../input/input";
import IconButton from "../../iconButton/iconButton";
import SearchIcon from "~/assets/icons/searchIcon";
import SearchItem from "../searchItem/searchItem";
import useDebounce from "~/hooks/useDebounce";
import { fetchApi } from "~/utils/common";
import * as productSlice from "~/store/slice/productSlice";

import styles from "./searchComponent.module.scss";
import generalStyles from "~/styles/generalStyle.module.scss";

function Search({ className }) {
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const debouncedValue = useDebounce(searchValue, 500);

  const inputRef = useRef();

  useEffect(() => {
    if (!debouncedValue.trim()) {
      setSearchResult([]);
      return;
    }

    setLoading(true);

    const getProducts = () =>
      fetchApi(productSlice.getAllBySearch(debouncedValue), dispatch);

    getProducts().then((result) => {
      setLoading(false);
      setSearchResult(result);
    });
  }, [debouncedValue]);

  const handleClear = () => {
    setSearchValue("");
    setSearchResult([]);
    inputRef.current.focus();
  };

  const handleHideResult = () => {
    setShowResult(false);
  };

  const handleChange = (e) => {
    const searchValue = e.target.value;

    if (!searchValue.startsWith(" ")) {
      setSearchValue(searchValue);
    }
  };

  return (
    <div className={clsx(styles.container, className)}>
      <HeadlessTippy
        interactive
        placement="bottom-start"
        visible={showResult && searchResult.length > 0}
        render={(attrs) => (
          <div tabIndex="-1" {...attrs}>
            <ul
              className={clsx(
                generalStyles.list,
                generalStyles.section,
                styles.list
              )}
            >
              {searchResult.map((result, index) => (
                <SearchItem data={result} key={index} />
              ))}
            </ul>
          </div>
        )}
        onClickOutside={handleHideResult}
      >
        <div className={styles.inputContainer}>
          <Input
            ref={inputRef}
            value={searchValue}
            placeholder="Tìm kiếm sản phẩm..."
            spellCheck={false}
            onChange={handleChange}
            onFocus={() => setShowResult(true)}
          >
            <IconButton className={styles.searchBtn}>
              <SearchIcon className={styles.searchIcon} />
            </IconButton>
          </Input>
          {!!searchValue && !loading && (
            <IconButton className={styles.clear} onClick={handleClear}>
              <i className="fa-solid fa-circle-xmark"></i>
            </IconButton>
          )}
          {loading && (
            <IconButton className={styles.loading}>
              <i className="fa-solid fa-spinner"></i>
            </IconButton>
          )}
        </div>
      </HeadlessTippy>
    </div>
  );
}

export default Search;

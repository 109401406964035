import { configureStore } from "@reduxjs/toolkit";
import locationReducer from "./slice/locationSlice";
import categoryReducer from "./slice/categorySlice";
import searchReducer from "./slice/searchSlice";
import bannerReducer from "./slice/bannerSlice";
import productReducer from "./slice/productSlice";
import userReducer from "./slice/productSlice";
import cartReducer from "./slice/cartSlice";
import orderReducer from "./slice/orderSlice";
import paymentMethodReducer from "./slice/paymentMethodSlice";
import subCategoryReducer from "./slice/subCategorySlice";
import cooperativeSourceReducer from "./slice/cooperativeSourceSlice";

const rootReducer = {
  location: locationReducer,
  category: categoryReducer,
  search: searchReducer,
  banner: bannerReducer,
  product: productReducer,
  user: userReducer,
  cart: cartReducer,
  order: orderReducer,
  subCategory: subCategoryReducer,
  paymentMethod: paymentMethodReducer,
  cooperativeSource: cooperativeSourceReducer,
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  stateSanitizer: (state) =>
    state.data ? { ...state, data: "<<LONG_BLOB>>" } : state,
});

export default store;

import httpRequest from "~/utils/httpRequest";

const isNotGift = { is_gift: "false" };

const pathName = "products/";

export const getAllProducts = async () => {
  try {
    const res = await httpRequest.get(`${pathName}g/`, {
      params: {
        ...isNotGift,
      },
    });
    return res.data;
  } catch (err) {}
};

export const getAllBySearch = async (params) => {
  try {
    const res = await httpRequest.get(`${pathName}`, {
      params: {
        search: params,
      },
    });
    return res.data;
  } catch (err) {}
};

export const getProductById = async (params) => {
  try {
    const res = await httpRequest.get(`${pathName}g/`, {
      params: {
        id: params,
      },
    });
    return res.data;
  } catch (err) {}
};

export const getProductsBySubCategory = async (params) => {
  try {
    const res = await httpRequest.get(`${pathName}g/`, {
      params: {
        sub_category: params,
        ...isNotGift,
      },
    });
    return res.data;
  } catch (err) {}
};

export const getProductsByCategory = async (params) => {
  try {
    const res = await httpRequest.get(`${pathName}g/`, {
      params: {
        category: params,
        ...isNotGift,
      },
    });
    return res.data;
  } catch (err) {}
};

export const getAllWithOneGift = async () => {
  try {
    const res = await httpRequest.get(`${pathName}g/`, {
      params: {
        gift_number: "1",
      },
    });
    return res.data;
  } catch (err) {}
};

export const getAllFromTwoGifts = async () => {
  try {
    const res = await httpRequest.get(`${pathName}g/`, {
      params: {
        gift_number: "many",
      },
    });
    return res.data;
  } catch (err) {}
};

export const filterAscending = async (params) => {
  try {
    const res = await httpRequest.get(`${pathName}g/`, {
      params: {
        filter: "ascending",
        sub_category: params,
        ...isNotGift,
      },
    });
    return res.data;
  } catch (err) {}
};

export const filterDescending = async (params) => {
  try {
    const res = await httpRequest.get(`${pathName}g/`, {
      params: {
        filter: "descending",
        sub_category: params,
        ...isNotGift,
      },
    });
    return res.data;
  } catch (err) {}
};

export const filterAlphabet = async (params) => {
  try {
    const res = await httpRequest.get(`${pathName}g/`, {
      params: {
        filter: "alphabet",
        sub_category: params,
        ...isNotGift,
      },
    });
    return res.data;
  } catch (err) {}
};
